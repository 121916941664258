import {
  ADD_ERROR_TO_CAMPAIGN,
  CampaignActions,
  GridViewActions,
  LOAD_CAMPAIGNS,
  SET_CAMPAIGNS,
} from 'src/constants';

export const loadCampaigns = (
  business,
  startYear,
  nextToken = null,
) => ({
  type: LOAD_CAMPAIGNS,
  payload: {
    business,
    startYear,
    nextToken,
  },
});
export const setCampaigns = (businessStartYear, campaigns) => ({
  type: SET_CAMPAIGNS,
  payload: {
    businessStartYear,
    campaigns,
  },
});
export const onAddCampaign = (packageId, business_startYear, campaign, options = {}) => {
  const {
    isClone = false,
    isBulkAction = false,
    hasFailedSlotsValidation = false,
  } = options;

  return {
    type: CampaignActions.ADD_CAMPAIGN,
    payload: {
      packageId,
      business_startYear,
      campaign,
      options: {
        isClone,
        isBulkAction,
        hasFailedSlotsValidation,
      },
    },
  };
};
export const onEditCampaign = (campaign) => ({
  type: CampaignActions.EDIT_CAMPAIGN,
  payload: {
    campaign,
  },
});
export const onDeleteCampaign = (campaign, options = {}) => {
  const { isBulkAction = false } = options;

  return {
    type: CampaignActions.DELETE_CAMPAIGN,
    payload: {
      campaign,
      options: {
        isBulkAction,
      }
    },
  };
};
export const onSendCampaignEdit = (id, packageId) => ({
  type: GridViewActions.SEND_CAMPAIGN_EDIT,
  payload: {
    id,
    packageId,
  },
});
export const onUpdateCampaignsSov = () => ({
  type: GridViewActions.UPDATE_CAMPAIGNS_SOV,
});
export const onAddErrorToCampaign = (campaign, column, message) => ({
  type: ADD_ERROR_TO_CAMPAIGN,
  data: { campaign, column, message },
});