import {
  onBulkImportSetPendingPackages,
  onBulkImportCompleteFileParsing,
} from '../../../actionCreators';
import { isBusinessFreshTraffic, isWebsiteOmnichannelViewMode } from '../../../selectors/sitewide';
import getNormalizedFileData from './getNormalizedFileData';
import { processBulkData } from './processBulkData';
import { BulkImportTypes, CampaignColumns } from 'src/constants';
import parseBulkImportString from './parseBulkImportString';

const processBulkImportData = (dispatch, state, data, columnOrder) => {
  const [headerColumns] = getNormalizedFileData(data, BulkImportTypes.FILE);
  const importColumnOrder = [...columnOrder];
  importColumnOrder.unshift(CampaignColumns.IS_PACKAGE, CampaignColumns.ID, CampaignColumns.PACKAGE_ID);
  const parsedCsvData = parseBulkImportString(data, importColumnOrder);
  const processedCampaigns = processBulkData(
    state,
    parsedCsvData,
    headerColumns,
    importColumnOrder,
    isWebsiteOmnichannelViewMode(state),
    isBusinessFreshTraffic(state)
  )
  dispatch(onBulkImportSetPendingPackages(processedCampaigns));
  dispatch(onBulkImportCompleteFileParsing());
};

export default processBulkImportData;
