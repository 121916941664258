import React from 'react';
import PropTypes from 'prop-types';
import SingleSelect from 'src/components/StyledInputs/SingleSelect';

const MarketingType = ({ intent, intents, onChange }) => {
  return (
    <SingleSelect
      inputValue={intent}
      options={intents}
      onInputChange={onChange}
      size="small"
    />
  );
};

MarketingType.propTypes = {
  intent: PropTypes.string.isRequired,
  intents: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MarketingType;
