import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import Loader from '@amzn/meridian/loader';
import { HashLink as Link } from 'react-router-hash-link';
import Icon from '@amzn/meridian/icon';
import refreshTokens from '@amzn/meridian-tokens/base/icon/refresh';
import Notification from '../Notification';
import NotificationsPaginator from '../NotificationsPaginator';
import NotificationsListPanel from './NotificationsListPanel';
import { notificationProps } from '../../proptypes';
import {
  Urls,
} from '../../constants';
import siteWideStyle from '../../styles/sitewide.module.scss';
import styles from './NotificationsList.module.scss';

const NotificationsList = (props) => {
  const {
    notificationsList,
    notificationsCount,
    onNotificationsLoad,
    onCloseNotificationsWidget,
    isLoading,
    isModal,
    isUpdating,
  } = props;

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader type="circular" size="small" />
      </div>
    );
  }

  const notifications = notificationsList.map((notification) => {
    const { id } = notification;

    return (
      <li className={styles.listItem} key={id}>
        <Notification
          isDisabledCheckbox={isUpdating}
          {...notification}
        />
      </li>
    );
  });

  const notificationsBlock = notificationsList.length
    ? (
      <ul className={styles.list}>
        {notifications}
      </ul>
    )
    : <p className={styles.noNotifications}>No notifications... yet.</p>;

  const seeAllNotificationsBlock = isModal
    ? (
      <div className={styles.seeAllNotifications}>
        <Link
          smooth
          to={`${Urls.NOTIFICATIONS}`}
          className={siteWideStyle.link}
        >
          See all notifications (
          {notificationsCount}
          )
        </Link>
      </div>
    )
    : null;

  const refreshButton = isModal
    ? null
    : (
      <div className={classnames([styles.buttonWrapper, styles.buttonRefresh])}>
        <Button
          type="link"
          onClick={onNotificationsLoad}
          data-testid="refresh-button"
        >
          <Icon tokens={refreshTokens}>
            Refresh Notifications feed
          </Icon>
        </Button>
      </div>
    );

  const closeButton = isModal
    ? (
      <div className={classnames([styles.buttonWrapper, styles.closeButton])}>
        <Button
          onClick={onCloseNotificationsWidget}
          type="link"
        >
          Close
        </Button>
      </div>
    )
    : null;

  const buttons = (
    <div className={styles.buttonsWrapper}>
      {refreshButton}
      {closeButton}
    </div>
  );

  const headerText = isModal ? 'Notifications' : `Notifications History feed (${notificationsCount})`;

  const paginationBlock = isModal
    ? null
    : (
      <div className={styles.pagination}>
        <NotificationsPaginator />
      </div>
    );

  const panelBlock = (
    <div className={styles.actionButtonsContainer}>
      <NotificationsListPanel notificationsList={notificationsList} />
    </div>
  );

  return (
    <div className={classnames({
      [styles.container]: true,
      [styles.widgetMode]: isModal,
    })}
    >
      <div className={styles.wrapper}>
        <div className={styles.innerContainer}>
          {buttons}
          <h1
            className={styles.heading}
          >
            {headerText}
          </h1>
          {notificationsBlock}
          {panelBlock}
          {seeAllNotificationsBlock}
        </div>
        {paginationBlock}
      </div>
    </div>
  );
};

NotificationsList.propTypes = {
  notificationsList: PropTypes.arrayOf(PropTypes.shape(notificationProps)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool,
  isModal: PropTypes.bool,
  onNotificationsLoad: PropTypes.func,
  onCloseNotificationsWidget: PropTypes.func,
  notificationsCount: PropTypes.number,
};

NotificationsList.defaultProps = {
  isModal: false,
  isUpdating: false,
  notificationsCount: 0,
  onNotificationsLoad: () => {
  },
  onCloseNotificationsWidget: () => {
  },
};

export default memo(NotificationsList);
