/* eslint-disable no-param-reassign, eqeqeq */
// campaign objects are mutated to include errors when found
import { CampaignColumns } from '../../../constants';
import { isArrayEmpty } from 'src/helpers/common';

const checkMetadata = (state, pkg, partialFieldsValidationList = {}) => {
  const { Meta: { metadata: { fields } } } = state;
  const fieldNames = Object.keys(fields);
  let invalidMetadataFields = false;

  const isPartialFieldsValidation = Object.keys(partialFieldsValidationList).length;
  fieldNames.forEach((fieldName) => {
    if (isPartialFieldsValidation && !partialFieldsValidationList[fieldName]) {
      return;
    }
    if (!pkg[fieldName] || fieldName === CampaignColumns.SOV.name) { return; }  
    const currentField = fields[fieldName];
    const packageValue = pkg[fieldName];
    const dependencies = currentField.dependsOn;
    let currentOptions = currentField.options;
    let hasParentDependencies = true;
    dependencies.forEach((dependency) => {
      const dependencyValue = pkg[dependency];
      if (hasParentDependencies && dependencyValue) {
        currentOptions = currentOptions[dependencyValue];
      }
      if (!currentOptions || !dependencyValue) {
        hasParentDependencies = false;
      }
    });
    // == due to "0.1" -> 0.1 conversions in SOV fields
    if (!hasParentDependencies || (!currentOptions.find((option) => option == packageValue) && !isArrayEmpty(packageValue))) {
      if (!pkg.errors) {
        pkg.errors = {};
      }
      if (!pkg.errors[fieldName]) { pkg.errors[fieldName] = {}; }
      pkg.errors[fieldName].text = `Value '${[packageValue]}' not found in metadata field '${fieldName}'`;
      invalidMetadataFields = true;
    }
  });
  return invalidMetadataFields;
};

export default checkMetadata;
