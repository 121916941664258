import {
  MetricLocations,
  CloudSettingsDataType,
} from '../constants';
import {
  onSetNewUserExperienceShown,
  onStartYearsReceived,
} from '../actionCreators';
import getUserSettings from 'src/middleware/UserSettings/getUserSettings';
import updateUserSettings from 'src/middleware/UserSettings/updateUserSettings';

export const getSelectedYear = (dispatch, getState, action, next) => {
  const location = MetricLocations.YEAR_SELECTOR;
  const dataType = CloudSettingsDataType.YEAR_SELECTOR_CFG;
  const payload = {
    metricLocation: location,
    dataType,
    successFunction: (response) => {
      if (!response) {
          dispatch(onSetNewUserExperienceShown(true));
      } else {
        dispatch(onStartYearsReceived(response.settings));
      }
    },
  };
  return getUserSettings(dispatch, getState, action, next, payload);
};

export const setSelectedYearsUserSettings = (dispatch, getState, action, next, yearType) => {
  const payload = {
    metricLocation: MetricLocations.YEAR_SELECTOR,
    dataType: CloudSettingsDataType.YEAR_SELECTOR_CFG,
    data: yearType,
  };
  return updateUserSettings(dispatch, getState, action, next, payload);
};
