import {
  onCustomViewSetColumnOrder,
  onCustomViewSetUseView,
} from 'src/actionCreators';
import {
  ON_METADATA_RECEIVED,
  CustomViewActions,
  MetricLocations,
  MetricTypes,
  MetricNames,
  CloudSettingsDataType,
} from 'src/constants';

import getUserSettings from 'src/middleware/UserSettings/getUserSettings';
import updateUserSettings from 'src/middleware/UserSettings/updateUserSettings';
import { submitAppSyncError } from 'src/metrics';

const getCustomViewUseSettings = (dispatch, getState, action, next) => {
  const location = MetricLocations.CUSTOM_VIEW_COLUMNS_MIDDLEWARE;
  const dataType = CloudSettingsDataType.CUSTOM_VIEW_ENABLED;
  try {
    const payload = {
      metricLocation: location,
      dataType,
      successFunction: (response) => {
        if (!response) {
          return null;
        }
        try {
            const shouldShow = JSON.parse(response.settings);
            dispatch(onCustomViewSetUseView(shouldShow));
        } catch (error) {
          const metricContext = {
            location: MetricLocations.CUSTOM_VIEW_COLUMNS_MIDDLEWARE,
            type: MetricTypes.APPSYNC,
            action: MetricNames.GET_USER_SETTING,
          };
          submitAppSyncError(error, metricContext);
        }
      },
    };
    return getUserSettings(dispatch, getState, action, next, payload);
  } catch {
    return null;
  }
};

const setCustomViewUseSettings = (dispatch, getState, action, next, settings) => {
  const payload = {
    metricLocation: MetricLocations.CUSTOM_VIEW_COLUMNS_MIDDLEWARE,
    dataType: CloudSettingsDataType.CUSTOM_VIEW_ENABLED,
    data: settings,
  };
  return updateUserSettings(dispatch, getState, action, next, payload);
};

const mergeNewColumns = (columnSettings, metadataColumnOrder) => {
  const knownColumns = columnSettings.map((column) => column.display);
  metadataColumnOrder.forEach((column) => {
    if (!knownColumns.includes(column.display)) {
      columnSettings.push({ ...column, isVisible: true });
    }
  });
  return columnSettings;
};

export const removeDuplicateColumns = (columnOrder) => {
  const uniqueColumns = new Map();
  columnOrder.forEach(column => uniqueColumns.set(column.name, column));

  return Array.from(uniqueColumns.values());
};

export default ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === ON_METADATA_RECEIVED) {
    next(action);
    const { Meta: { metadata: { columnOrder: metadataColumnOrder } } } = getState();
    const location = MetricLocations.CUSTOM_VIEW_COLUMNS_MIDDLEWARE;
    const dataType = CloudSettingsDataType.CUSTOM_VIEW_COLUMNS;
    const payload = {
      metricLocation: location,
      dataType,
      successFunction: (response) => {
        if (!response) {
          return null;
        }
        try {
          const columnOrder = JSON.parse(response.settings) || null;
          if (columnOrder) {
            getCustomViewUseSettings(dispatch, getState, action, next);
            const updatedColumnOrder = mergeNewColumns(columnOrder,
              metadataColumnOrder);
            dispatch(onCustomViewSetColumnOrder(updatedColumnOrder));
          }
        } catch (error) {
          const metricContext = {
            location: MetricLocations.CUSTOM_VIEW_COLUMNS_MIDDLEWARE,
            type: MetricTypes.APPSYNC,
            action: MetricNames.GET_USER_SETTING,
          };
          submitAppSyncError(error, metricContext);
        }
      },
    };
    return getUserSettings(dispatch, getState, action, next, payload);
  }

  if (action.type === CustomViewActions.SET_COLUMN_ORDER) {
    const payload = {
      metricLocation: MetricLocations.CUSTOM_VIEW_COLUMNS_MIDDLEWARE,
      dataType: CloudSettingsDataType.CUSTOM_VIEW_COLUMNS,
      data: JSON.stringify(removeDuplicateColumns(action.payload)),
    };
    return updateUserSettings(dispatch, getState, action, next, payload);
  }

  if (action.type === CustomViewActions.SET_USE_VIEW) {
    setCustomViewUseSettings(dispatch, getState, action, next, action.payload);
  }

  return next(action);
};
