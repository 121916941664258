import React from 'react';
import PropTypes from 'prop-types';
import SingleSelect from 'src/components/StyledInputs/SingleSelect';

const Objective = ({ objective, objectives, onChange }) => {
  return (
    <SingleSelect
      inputValue={objective}
      options={objectives}
      onInputChange={onChange}
      size="small"
    />
  );
};

Objective.propTypes = {
  objective: PropTypes.string.isRequired,
  objectives: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Objective;
