import { DashboardActions } from 'src/constants';

export const onDashboardInit = () => ({
  type: DashboardActions.INIT,
});
export const onDashboardDateRangeSet = (dateRange) => ({
  type: DashboardActions.SET_DATE_RANGE,
  data: {
    dateRange,
  },
});
export const onDashboardRunCalc = () => ({
  type: DashboardActions.RUN_CALC,
});
export const onDashboardStatsSet = (stats) => ({
  type: DashboardActions.SET_DATA,
  data: {
    stats,
  },
});
export const onDashboardModeToggle = () => ({
  type: DashboardActions.TOGGLE_MODE,
});
export const onDashboardStartTimeSet = ({ startTime }) => ({
  type: DashboardActions.SET_START_TIME,
  data: {
    startTime,
  },
});
export const onDashboardEndTimeSet = ({ endTime }) => ({
  type: DashboardActions.SET_END_TIME,
  data: {
    endTime,
  },
});