import {
  GraphQLLabels, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphql';
import { deleteComment } from '../../graphql/mutations';
import { submitAppSyncError, submitError } from '../../metrics';

export default (dispatch, getState, action) => {
  const { User: { currentUser: { username } } } = getState();
  const { payload: { campaignId, createdAt } } = action;

  const metricContext = {
    location: MetricLocations.COMMENTS_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.CREATE_COMMENT,
    username,
  };

  const input = {
    comment: {
      campaignId,
      createdAt,
    }
  };

  dispatch(onGraphQLAction({
    graphqlAction: deleteComment,
    data: {
      input,
    },
    label: GraphQLLabels.DELETE_COMMENT,
    onSuccessFunction({ data: { deleteComment: deletedComment } }) {
      if (!deletedComment) {
        submitError('deletedComment returned an empty result', metricContext);
      }
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));
};
