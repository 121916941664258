import { CommentActions } from 'src/constants';

export const onCommentsLoad = ({ campaignId, nextToken = null }) => ({
  type: CommentActions.LOAD,
  payload: {
    campaignId,
    nextToken,
  },
});
export const onCommentsSet = ({ comments, shouldOverride = false }) => ({
  type: CommentActions.SET,
  payload: {
    comments,
    shouldOverride,
  },
});
export const onCommentsSort = () => ({
  type: CommentActions.SORT,
});
export const onCommentsAdd = ({ campaignId, message }) => ({
  type: CommentActions.ADD,
  payload: {
    campaignId,
    message,
  },
});
export const onCommentsSetPosting = (flag) => ({
  type: CommentActions.SET_POSTING,
  payload: {
    flag,
  },
});
export const onCommentsDelete = ({ campaignId, createdAt }) => ({
  type: CommentActions.DELETE,
  payload: {
    campaignId,
    createdAt,
  },
});
export const onCommentsToggleAutoScrolling = () => ({
  type: CommentActions.TOGGLE_AUTO_SCROLLING,
});
export const onCommentsReset = () => ({
  type: CommentActions.RESET,
});
export const onCommentsStartPolling = () => ({
  type: CommentActions.START_POLLING,
});
export const onCommentsStopPolling = () => ({
  type: CommentActions.STOP_POLLING,
});
export const onCommentsSetActiveCampaign = ({ id, packageId }) => ({
  type: CommentActions.SET_ACTIVE_CAMPAIGN,
  payload: {
    campaignId: id,
    packageId,
  },
});
export const onCommentsModalOpen = () => ({
  type: CommentActions.OPEN_MODAL,
});
export const onCommentsModalClose = () => ({
  type: CommentActions.CLOSE_MODAL,
});