/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      asins
      brand
      business_startYear
      campaignName
      campaignType
      category
      channel
      copy
      createdAt
      endDateTime
      id
      isLocked
      isDeal
      language
      lockMessage
      lockUser
      marketingManagerNotes
      otherTheme
      packageId
      placementSource
      placementInBundle
      promoId
      promoType
      rateCardId
      refMarker
      scheduler
      schedulingInstructions
      spanishCopy
      startDateTime
      status
      timezoneId
      translationStatus
      updatedAt
      version
      almStores
      browseNodeIds
      cbrNotes
      contraCogs
      composerComponentJson
      csCampaignIds {
        creativeId
        desktopPlacementId
        mobilePlacementId
      }
      customSeeMoreUrl
      dedupeRules
      desktopSlot
      encodedKeplerWidgetParam
      functionalQANotes
      heroAsins
      imagePaths
      imageType
      inventory
      inventoryAwareness
      isAnimatedContent
      label
      melodyProjectId
      merchant
      mobileSlot
      needsInventoryAwareness
      pageId
      pageName
      pageTheme
      pageType
      percolateParameters
      placement
      shovelerContent
      slotsOverbookedValidationBypassed
      sov
      sovType
      storefrontReviewNotes
      subHeadline
      symphonyId
      symphonyUrl
      targeting
      targetingExclude
      termsAndConditions
      useNewTemplateDesign
      vssmId
      weblab
      weblabTreatment
      widget
      altText
      bodyCopy
      cbrSim
      country
      creativeStatus
      cta
      customerCtaUrl
      designSim
      desktopImage1x
      desktopImage2x
      device
      frequency
      headline
      hva
      initiative
      mobileImage1x
      mobileImage2x
      seasonalEvent
      tabletImage1x
      tabletImage2x
      targetBusiness
      targetingType
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      asins
      brand
      business_startYear
      campaignName
      campaignType
      category
      channel
      copy
      createdAt
      endDateTime
      id
      isLocked
      isDeal
      language
      lockMessage
      lockUser
      marketingManagerNotes
      otherTheme
      packageId
      placementSource
      placementInBundle
      promoId
      promoType
      rateCardId
      refMarker
      scheduler
      schedulingInstructions
      spanishCopy
      startDateTime
      status
      timezoneId
      translationStatus
      updatedAt
      version
      almStores
      browseNodeIds
      cbrNotes
      contraCogs
      composerComponentJson
      csCampaignIds {
        creativeId
        desktopPlacementId
        mobilePlacementId
      }
      customSeeMoreUrl
      dedupeRules
      desktopSlot
      encodedKeplerWidgetParam
      functionalQANotes
      heroAsins
      imagePaths
      imageType
      inventory
      inventoryAwareness
      isAnimatedContent
      label
      melodyProjectId
      merchant
      mobileSlot
      needsInventoryAwareness
      pageId
      pageName
      pageTheme
      pageType
      percolateParameters
      placement
      shovelerContent
      slotsOverbookedValidationBypassed
      sov
      sovType
      storefrontReviewNotes
      subHeadline
      symphonyId
      symphonyUrl
      targeting
      targetingExclude
      termsAndConditions
      useNewTemplateDesign
      vssmId
      weblab
      weblabTreatment
      widget
      altText
      bodyCopy
      cbrSim
      country
      creativeStatus
      cta
      customerCtaUrl
      designSim
      desktopImage1x
      desktopImage2x
      device
      frequency
      headline
      hva
      initiative
      mobileImage1x
      mobileImage2x
      seasonalEvent
      tabletImage1x
      tabletImage2x
      targetBusiness
      targetingType
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      asins
      brand
      business_startYear
      campaignName
      campaignType
      category
      channel
      copy
      createdAt
      endDateTime
      id
      isLocked
      isDeal
      language
      lockMessage
      lockUser
      marketingManagerNotes
      otherTheme
      packageId
      placementSource
      placementInBundle
      promoId
      promoType
      rateCardId
      refMarker
      scheduler
      schedulingInstructions
      spanishCopy
      startDateTime
      status
      timezoneId
      translationStatus
      updatedAt
      version
      almStores
      browseNodeIds
      cbrNotes
      contraCogs
      composerComponentJson
      csCampaignIds {
        creativeId
        desktopPlacementId
        mobilePlacementId
      }
      customSeeMoreUrl
      dedupeRules
      desktopSlot
      encodedKeplerWidgetParam
      functionalQANotes
      heroAsins
      imagePaths
      imageType
      inventory
      inventoryAwareness
      isAnimatedContent
      label
      melodyProjectId
      merchant
      mobileSlot
      needsInventoryAwareness
      pageId
      pageName
      pageTheme
      pageType
      percolateParameters
      placement
      shovelerContent
      slotsOverbookedValidationBypassed
      sov
      sovType
      storefrontReviewNotes
      subHeadline
      symphonyId
      symphonyUrl
      targeting
      targetingExclude
      termsAndConditions
      useNewTemplateDesign
      vssmId
      weblab
      weblabTreatment
      widget
      altText
      bodyCopy
      cbrSim
      country
      creativeStatus
      cta
      customerCtaUrl
      designSim
      desktopImage1x
      desktopImage2x
      device
      frequency
      headline
      hva
      initiative
      mobileImage1x
      mobileImage2x
      seasonalEvent
      tabletImage1x
      tabletImage2x
      targetBusiness
      targetingType
    }
  }
`;
export const createPackage = /* GraphQL */ `
  mutation CreatePackage($input: CreatePackageInput!) {
    createPackage(input: $input) {
      business_startYear
      id
      brand
      category
      endDateTime
      isDeal
      marketingManager
      marketingManagerNotes
      packageName
      placementSource
      program
      rateCardId
      refMarker
      startDateTime
      status
      timezoneId
      vendor
      vertical
      version
      updatedAt
      createdAt
    }
  }
`;
export const deletePackage = /* GraphQL */ `
  mutation DeletePackage($input: DeletePackageInput!) {
    deletePackage(input: $input) {
      business_startYear
      id
      brand
      category
      endDateTime
      isDeal
      marketingManager
      marketingManagerNotes
      packageName
      placementSource
      program
      rateCardId
      refMarker
      startDateTime
      status
      timezoneId
      vendor
      vertical
      version
      updatedAt
      createdAt
    }
  }
`;
export const updatePackage = /* GraphQL */ `
  mutation UpdatePackage($input: UpdatePackageInput!) {
    updatePackage(input: $input) {
      business_startYear
      id
      brand
      category
      endDateTime
      isDeal
      marketingManager
      marketingManagerNotes
      packageName
      placementSource
      program
      rateCardId
      refMarker
      startDateTime
      status
      timezoneId
      vendor
      vertical
      version
      updatedAt
      createdAt
    }
  }
`;
export const updateMetadata = /* GraphQL */ `
  mutation UpdateMetadata($input: UpdateMetadataInput!) {
    updateMetadata(input: $input) {
      business
      metadata
      version
    }
  }
`;
export const createOrUpdateCsCampaign = /* GraphQL */ `
  mutation CreateOrUpdateCsCampaign($input: CreateOrUpdateCSCampaignInput!) {
    createOrUpdateCSCampaign(input: $input) {
      existingCampaign {
        creative {
          id
          displayName
          description
          owner
          businessGroup
          accessControlGroupId
          objective
          intent
          creativeUrl
          errorMessage
        }
        desktopPlacement {
          id
          eligibilityCriteriaRule
          guaranteePercentage
          errorMessage
        }
        mobilePlacement {
          id
          eligibilityCriteriaRule
          guaranteePercentage
          errorMessage
        }
        content {
          id
          component
          resources
          errorMessage
        }
      }
      updatedCampaign {
        creative {
          id
          displayName
          description
          owner
          businessGroup
          accessControlGroupId
          objective
          intent
          creativeUrl
          errorMessage
        }
        desktopPlacement {
          id
          eligibilityCriteriaRule
          guaranteePercentage
          errorMessage
        }
        mobilePlacement {
          id
          eligibilityCriteriaRule
          guaranteePercentage
          errorMessage
        }
        content {
          id
          component
          resources
          errorMessage
        }
      }
      csCampaignIds {
        creativeId
        desktopPlacementId
        mobilePlacementId
      }
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement($input: CreateAnnouncementInput!) {
    createAnnouncement(input: $input) {
      business
      id
      isSticky
      isGlobalAnnouncement
      message
      startDateTime
      endDateTime
      createdAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement($input: DeleteAnnouncementInput!) {
    deleteAnnouncement(input: $input) {
      business
      id
      isSticky
      isGlobalAnnouncement
      message
      startDateTime
      endDateTime
      createdAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement($input: UpdateAnnouncementInput!) {
    updateAnnouncement(input: $input) {
      business
      id
      isSticky
      isGlobalAnnouncement
      message
      startDateTime
      endDateTime
      createdAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      campaignId
      message
      createdBy
      createdAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      campaignId
      message
      createdBy
      createdAt
    }
  }
`;
export const createUserSession = /* GraphQL */ `
  mutation CreateUserSession($input: CreateUserSessionInput!) {
    createUserSession(input: $input) {
      username
      last_notification_seen_at
    }
  }
`;
export const renewUserSession = /* GraphQL */ `
  mutation RenewUserSession($input: RenewUserSessionInput!) {
    renewUserSession(input: $input) {
      username
      last_notification_seen_at
    }
  }
`;
export const createUserSetting = /* GraphQL */ `
  mutation CreateUserSetting($input: CreateUserSettingsInput!) {
    createUserSetting(input: $input) {
      username
      dataType
      settings
    }
  }
`;
export const updateUserSetting = /* GraphQL */ `
  mutation UpdateUserSetting($input: UpdateUserSettingsInput!) {
    updateUserSetting(input: $input) {
      username
      dataType
      settings
    }
  }
`;
export const deleteUserSetting = /* GraphQL */ `
  mutation DeleteUserSetting($input: DeleteUserSettingsInput!) {
    deleteUserSetting(input: $input) {
      username
      dataType
      settings
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      notificationType
      business_startYear
      toUsername
      fromUsername
      campaignId
      packageId
      name
      column
      oldValue
      newValue
      isRead
      createdAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      notificationType
      business_startYear
      toUsername
      fromUsername
      campaignId
      packageId
      name
      column
      oldValue
      newValue
      isRead
      createdAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      notificationType
      business_startYear
      toUsername
      fromUsername
      campaignId
      packageId
      name
      column
      oldValue
      newValue
      isRead
      createdAt
    }
  }
`;
export const createRateCard = /* GraphQL */ `
  mutation CreateRateCard($input: CreateRateCardInput!) {
    createRateCard(input: $input) {
      business
      id
      vendor
      marketingManager
      userInput
      configVersion
      note
      isAssetPublished
      version
    }
  }
`;
export const updateRateCard = /* GraphQL */ `
  mutation UpdateRateCard($input: UpdateRateCardInput!) {
    updateRateCard(input: $input) {
      business
      id
      vendor
      marketingManager
      userInput
      configVersion
      note
      isAssetPublished
      version
    }
  }
`;
export const deleteRateCard = /* GraphQL */ `
  mutation DeleteRateCard($input: DeleteRateCardInput!) {
    deleteRateCard(input: $input) {
      business
      id
      vendor
      marketingManager
      userInput
      configVersion
      note
      isAssetPublished
      version
    }
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      rateCardId
      id
      campaign
      version
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      rateCardId
      id
      campaign
      version
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      rateCardId
      id
      campaign
      version
    }
  }
`;
