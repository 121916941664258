// @deprecated
// TODO: deprecate/replace prop types with TS types everywhere in the project
import PropTypes from 'prop-types';
import { CampaignColumns, InputTypes } from '../constants';

const {
  ID,
  STATUS,
  SCHEDULER,
  VERTICAL,
  VENDOR,
  BRAND,
  MERCHANT,
  PLATFORM,
  PAGETYPE,
  PAGE_NAME,
  PLACEMENT,
  START_DATE,
  END_DATE,
  DESKTOP_SLOT,
  MOBILE_SLOT,
  CAMPAIGN_MARKER,
  CAMPAIGN_NAME,
  HERO_ASINS,
  ASINS,
  COPY,
  SYMPHONY_URL,
  MARKETING_MANAGER_NOTES,
  TARGETING,
  TARGETING_EXCLUDE,
  DEDUPE_RULES,
  SUB_HEADLINE,
  TERMS_AND_CONDITIONS,
  USE_NEW_TEMPLATE,
  DESIGN_SIM,
  SCHEDULING_INSTRUCTIONS,
  CONTRA_COGS,
  IS_ANIMATED_CONTENT,
  SPANISH_COPY,
  TRANSLATION_STATUS,
} = CampaignColumns;

export const campaignProps = PropTypes.shape({
  [ID]: PropTypes.string,
  [STATUS]: PropTypes.string,
  [SCHEDULER]: PropTypes.string,
  [PAGETYPE]: PropTypes.string,
  [VERTICAL]: PropTypes.string,
  [VENDOR]: PropTypes.string,
  [BRAND]: PropTypes.string,
  [MERCHANT]: PropTypes.string,
  [PLATFORM]: PropTypes.string,
  [PAGE_NAME]: PropTypes.string,
  [PLACEMENT]: PropTypes.string,
  [START_DATE]: PropTypes.string,
  [END_DATE]: PropTypes.string,
  [DESKTOP_SLOT]: PropTypes.string,
  [MOBILE_SLOT]: PropTypes.string,
  [CAMPAIGN_MARKER]: PropTypes.string,
  [CAMPAIGN_NAME]: PropTypes.string,
  [HERO_ASINS]: PropTypes.string,
  [ASINS]: PropTypes.string,
  [COPY]: PropTypes.string,
  [SYMPHONY_URL]: PropTypes.string,
  [MARKETING_MANAGER_NOTES]: PropTypes.string,
  [TARGETING]: PropTypes.string,
  [TARGETING_EXCLUDE]: PropTypes.string,
  [DEDUPE_RULES]: PropTypes.string,
  [SUB_HEADLINE]: PropTypes.string,
  [TERMS_AND_CONDITIONS]: PropTypes.string,
  [USE_NEW_TEMPLATE]: PropTypes.string,
  [DESIGN_SIM]: PropTypes.string,
  [SCHEDULING_INSTRUCTIONS]: PropTypes.string,
  [CONTRA_COGS]: PropTypes.string,
  [IS_ANIMATED_CONTENT]: PropTypes.bool,
  [SPANISH_COPY]: PropTypes.bool,
  [TRANSLATION_STATUS]: PropTypes.bool,
});
export const campaignInputProps = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.arrayOf(PropTypes.string),
]);
export const campaignColumnProps = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string,
  display: PropTypes.string,
  inputType: PropTypes.oneOf(Object.keys(InputTypes)),
}));
export const styledInputProps = {
  onSubmit: PropTypes.func,
  inputValue: campaignInputProps,
  onInputChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
};
export const campaignFormProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
export const announcementFormProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      business: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export const filterProps = PropTypes.shape({
  column: PropTypes.string,
  comparator: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
export const sortProps = PropTypes.shape({

});
export const filterViewProps = PropTypes.shape({
  name: PropTypes.string,
  filters: PropTypes.arrayOf(filterProps),
  sorts: PropTypes.arrayOf(sortProps),
});
export const userPanelProps = {
  username: PropTypes.string.isRequired,
  realName: PropTypes.string.isRequired,
  onSignOut: PropTypes.func.isRequired,
  isNotificationsWidgetEnabled: PropTypes.bool.isRequired,
};
export const slotUtilizationProps = PropTypes.shape({
  numberOfCampaigns: PropTypes.number.isRequired,
  totalSOV: PropTypes.number.isRequired,
  slotName: PropTypes.string,
});
export const slotUtilizationItemProp = PropTypes.shape({
  slotName: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  placement: PropTypes.string.isRequired,
  utilization: PropTypes.objectOf(
    slotUtilizationProps,
  ),
});
export const utilizationOrderedSlotProp = PropTypes.shape({
  slotName: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
});
export const formControlInputProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
});
export const formControlProps = {
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: formControlInputProps.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
};
export const formCampaignColumns = {
  campaignColumns: campaignProps.isRequired,
};
export const campaignFormUtilizationProps = PropTypes.shape({
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  timezoneID: PropTypes.string,
  desktopUtilizationData: PropTypes.shape({
    slotUtilizationProps,
  }),
  mobileUtilizationData: PropTypes.shape({
    slotUtilizationProps,
  }),
});

export const dashboardProps = {
  totalCampaigns: PropTypes.number,
  totalCampaignsUrl: PropTypes.string,
  totalMarketingManager: PropTypes.number,
  totalMarketingManagerUrl: PropTypes.string,
  totalScheduler: PropTypes.number,
  totalSchedulerUrl: PropTypes.string,
  statsByGroups: PropTypes.arrayOf(
    PropTypes.shape({
      groupName: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          columnValue: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  openItemsIds: PropTypes.objectOf(PropTypes.bool).isRequired,
  toggleOpenItem: PropTypes.func.isRequired,
  isFreshBusiness: PropTypes.bool,
};

export const notificationProps = {
  id: PropTypes.string.isRequired,
  notificationType: PropTypes.string.isRequired,
  business_startYear: PropTypes.string.isRequired,
  packageId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  fromUsername: PropTypes.string.isRequired,
  toUsername: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  column: PropTypes.string,
  oldValue: PropTypes.string,
  newValue: PropTypes.string,
};

export const commentProps = {
  campaignId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export const metadataAuditLogChangeProps = PropTypes.shape({
  metadataDiff: PropTypes.objectOf(PropTypes.object),
  version: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  username: PropTypes.string,
  date: PropTypes.string,
});

export const CsDiffProps = PropTypes.shape({
  diffType: PropTypes.string,
  oldValue: PropTypes.string,
  newValue: PropTypes.string,
});

export const rateCardProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  business: PropTypes.string.isRequired,
  vendor: PropTypes.string,
  marketingManager: PropTypes.string,
  configVersion: PropTypes.string,
  note: PropTypes.string,
});

export const CalendarPlacementProps = PropTypes.shape({
  displayName: PropTypes.string,
  guaranteePercentage: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
});

export const groupedPlacementProps = PropTypes.arrayOf(
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
  ),
);
