/* eslint-disable no-mixed-operators */
import _ from 'lodash';
import moment from 'moment';
import { getObjectDiff } from "@donedeal0/superdiff";
import { EVERGREEN_YEAR } from '../../../constants/dateTime';
import { joinBusinessStartYear } from '../../../helpers/common';
import {
  getSelectedBusiness, 
} from '../../../selectors/sitewide';
import {
  BusinessYear,
  CampaignColumns,
  CampaignTypes,
} from '../../../constants';
import { ColumnStatus } from 'src/constants/bulkImport';
import { getPackageColumnNames } from '../../../helpers/package';
import { getCampaignColumnNames } from '../../../helpers/campaign';
import { validateBulkImportPackage } from './validateCampaign';
import {
  getPackagesForCurrentBusinessStartYears
} from '../../../selectors/package';
import { isPartialBulkImportMode } from '../../../selectors/bulkImport';

// override method to handle future dates `1/1/99 -> 2099-01-01`
moment.parseTwoDigitYear = function (year) {
  return parseInt(year, 10) + 2000;
}

const getColumnsListForPartialValidation = (obj) => {
  const map = {};
  /* eslint-disable no-unused-vars */
  Object.entries(obj).forEach(([_, { name }]) => {
    map[name] = true;
  });
  return map;
};

const getAllowedColumnNames = (headerColumns, columnOrder) => {
  const allowedColumnsNames = {};
  
  columnOrder.forEach(({ name, display }) => {
    headerColumns.forEach((headerColumn, headerIndex) => {
      if (headerColumn.toLowerCase().trim() === display.toLowerCase().trim()) {
        allowedColumnsNames[headerIndex] = {
          name,
          display
        };
      }
    });
  });
  return allowedColumnsNames;
}

const checkMismatchedColumnNames = (columnOrder, headerColumns, allowedColumnsNames) => {
  const allowedColumnDisplays = Object.values(allowedColumnsNames).map(col => col.display);

  const mismatchedColumnNames = {
    wrongColumnNames: headerColumns.filter(col => !allowedColumnDisplays.includes(col)),
    missingColumnNames: Object.keys(columnOrder).filter(key => !headerColumns.includes(columnOrder[key].display))
      .map(key => columnOrder[key].display)
  };

  return mismatchedColumnNames;
}

const createPackageFromCsvRow = (row, columns, business) => {
  const packageCols = getPackageColumnNames();
  const pkg = {};
  Object.values(columns).forEach((col) => {
    if (col.name === CampaignColumns.END_DATE.name) { // packages ending in 2099 are Evergreen
      const parsedEndDate = moment(row[col.name], "MM/DD/YYYY");
      if (parsedEndDate.year() === EVERGREEN_YEAR) {
        pkg[CampaignColumns.BUSINESS_START_YEAR.name] = joinBusinessStartYear(business, BusinessYear.EVERGREEN)
      }
      pkg[col.name] = parsedEndDate.format("YYYY-MM-DD");
    }
    else if (col.name === CampaignColumns.START_DATE.name) {
      const parsedStartDate = moment(row[col.name], "MM/DD/YYYY");
      if (!pkg[CampaignColumns.BUSINESS_START_YEAR.name]) {
        pkg[CampaignColumns.BUSINESS_START_YEAR.name] = joinBusinessStartYear(business, parsedStartDate.year());
      }
      pkg[col.name] = parsedStartDate.format("YYYY-MM-DD");
    }
    else if (packageCols.includes(col.name)) {
      pkg[col.name] = row[col.name];
    }
  });
  return {
    ...pkg,
    campaigns: [],
  }
};

const createCampaignFromCsvRow = (row, columns, pkg) => {
  const campaignCols = getCampaignColumnNames();
  const campaign = {}
  campaign[CampaignColumns.BUSINESS_START_YEAR.name] = pkg[CampaignColumns.BUSINESS_START_YEAR.name]
  Object.values(columns).forEach((col) => {
    if (col.name === CampaignColumns.START_DATE.name || col.name === CampaignColumns.END_DATE.name) {
      campaign[col.name] = moment(row[col.name], "MM/DD/YYYY").format("YYYY-MM-DD");
    }
    else if (campaignCols.includes(col.name) || col.name === CampaignColumns.ID.name) {
      campaign[col.name] = row[col.name];
    }
  })
  return campaign;
};

const getMergedPackage = (columns, existingPackage, currentPackage) => {
  const packageCols = getPackageColumnNames();
  const mergedPackage = _.cloneDeep(existingPackage);
  const columnNamesWithChange = new Set();
  const packageDiff = getObjectDiff(existingPackage, currentPackage);

  packageDiff.diff.forEach((difference) => {
    if (difference.status === ColumnStatus.UPDATED) {
      if (packageCols.includes(difference.property)) { // property refers to the column name
        mergedPackage[difference.property] = difference.currentValue;
        columnNamesWithChange.add(difference.property);
      } else if (difference.property === 'campaigns') {
        const existingCampaignsMap = new Map(
          mergedPackage.campaigns.map(campaign => [campaign.id, campaign])
        )
        currentPackage.campaigns.forEach((currentCampaign) => {
          if (existingCampaignsMap.has(currentCampaign.id)) {
            const existingCampaign = existingCampaignsMap.get(currentCampaign.id);
            const campaignDiff = getObjectDiff(existingCampaign, currentCampaign);
            campaignDiff.diff.forEach((campaignDifference) => {
              if (campaignDifference.status === ColumnStatus.UPDATED) {
                existingCampaign[campaignDifference.property] = campaignDifference.currentValue;
                columnNamesWithChange.add(campaignDifference.property);
              }
            });
          } else {
            mergedPackage.campaigns.push(currentCampaign);
          }
        })
      }
    }
  })

  mergedPackage.changes = Array.from(columnNamesWithChange);
  delete mergedPackage.packageId;
  return mergedPackage;
};

const processPackage = (formattedRow, currentPackageId, existingPackage, rejected, create, update, state, columnsListForValidation, allowedColumnsNames, isPartialBulkUploadMode) => {
  const numberOfCampaignsInCurrentPkg = formattedRow.campaigns ? formattedRow.campaigns.length : 0;
  const numberOfCampaignsInExistingPkg = (existingPackage && existingPackage.campaigns) ? existingPackage.campaigns.length : 0;

  if (existingPackage) {
    const mergedPackage = getMergedPackage(allowedColumnsNames, existingPackage, formattedRow);
    if ((numberOfCampaignsInCurrentPkg !== numberOfCampaignsInExistingPkg) || mergedPackage.changes.length) {
      if (isPartialBulkUploadMode) {
        if (validateBulkImportPackage(state, formattedRow, columnsListForValidation)) {
          rejected.push(formattedRow);
        } else {
          update.push(mergedPackage);
        }
      } else {
        if (validateBulkImportPackage(state, mergedPackage)) {
          rejected.push(mergedPackage);
        } else {
          update.push(mergedPackage);
        }
      }
    }
  } else {
    if (validateBulkImportPackage(state, formattedRow)) {
      rejected.push(formattedRow)
    } else {
      create.push(formattedRow);
    }
  }
}

export const processBulkData = (
  state,
  parsedCsvData,
  headerColumns,
  columnOrder,
  isOmnichannelMode,
) => {

  const packageMap = getPackagesForCurrentBusinessStartYears(state);
  const isPartialBulkUploadMode = isPartialBulkImportMode(state);

  const create = [];
  const update = [];
  const rejected = [];
  let formatIssues = 0;

  const allowedColumnsNames = getAllowedColumnNames(headerColumns, columnOrder);
  const mismatchedColumnNames = checkMismatchedColumnNames(columnOrder, headerColumns, allowedColumnsNames);

  if (mismatchedColumnNames.wrongColumnNames.length) {
    return { accepted: { create, update }, rejected, formatIssues, mismatchedColumnNames }
  }

  const columnsListForValidation = isPartialBulkUploadMode ? getColumnsListForPartialValidation(allowedColumnsNames) : {};
  const allowedColumnsLength = Object.keys(allowedColumnsNames).length;

  if (isOmnichannelMode) {
    for (let i = 0; i < parsedCsvData.data.length; i++) {
      let currentRow = parsedCsvData.data[i];
      let campaignCounter = 1;
      let formattedRow, currentPackageId;

      if (Object.keys(currentRow).length !== allowedColumnsLength) {
        formatIssues++;
        continue;
      }
      if (_.isEmpty(currentRow) || _.isUndefined(currentRow)) {
        continue;
      }
      if (currentRow.isPackage) {
        formattedRow = createPackageFromCsvRow(currentRow, allowedColumnsNames, getSelectedBusiness(state)); // create base package object from row data
        currentPackageId = currentRow.packageId;
        const existingPackage = currentPackageId ? packageMap[currentPackageId] : undefined;

        currentRow = parsedCsvData.data[i + 1]; // jump ahead to next row to see if its a campaign, and begin our campaign conversion logic
        while (!_.isUndefined(currentRow) && _.isEmpty(currentRow?.isPackage)) {
          const campaign = createCampaignFromCsvRow(currentRow, allowedColumnsNames, formattedRow, true);
          formattedRow.campaigns.push(campaign);
          campaignCounter += 1;
          currentRow = parsedCsvData.data[i + campaignCounter]; // increment relative to the # of campaigns we've parsed
        }
        processPackage(formattedRow, currentPackageId, existingPackage, rejected, create, update, state, columnsListForValidation, allowedColumnsNames, isPartialBulkUploadMode);
        i += campaignCounter - 1;
      }
    }
  } else {
    for (let i = 0; i < parsedCsvData.data.length; i++) {
      let currentRow = parsedCsvData.data[i];
      let formattedRow, currentPackageId;

      if (Object.keys(currentRow).length !== allowedColumnsLength) {
        formatIssues++;
        continue;
      }
      if (_.isEmpty(currentRow) || _.isUndefined(currentRow)) {
        continue;
      }
      formattedRow = createPackageFromCsvRow(currentRow, allowedColumnsNames, getSelectedBusiness(state)); // create base package object from row data
      currentPackageId = currentRow.packageId;
      const existingPackage = currentPackageId ? packageMap[currentPackageId] : undefined;
      formattedRow.campaigns[0] = createCampaignFromCsvRow(currentRow, allowedColumnsNames, formattedRow, false);
      formattedRow.campaigns[0].campaignType = CampaignTypes.ONLINE; // all non-omni campaigns are online

      processPackage(formattedRow, currentPackageId, existingPackage, rejected, create, update, state, columnsListForValidation, allowedColumnsNames, isPartialBulkUploadMode);
    }
  }
  return { accepted: { create, update }, rejected, formatIssues, mismatchedColumnNames };

}
