import { RateCardActions } from 'src/constants';

export const onRateCardLoadList = (nextToken = null) => ({
  type: RateCardActions.LOAD_LIST,
  payload: {
    nextToken,
  },
});
export const onRateCardSetList = (cards) => ({
  type: RateCardActions.SET_LIST,
  payload: {
    cards,
  },
});
export const onRateCardSetListLoaded = () => ({
  type: RateCardActions.SET_LIST_LOADED,
});
export const onRateCardSetPagination = (pageNumber) => ({
  type: RateCardActions.SET_PAGE_NUMBER,
  payload: {
    pageNumber,
  },
});
export const onRateCardSetAssetsPagination = (pageNumberAssets) => ({
  type: RateCardActions.SET_PAGE_NUMBER_ASSETS,
  payload: {
    pageNumberAssets,
  },
});
export const onRateCardSetAssetsList = (assets) => ({
  type: RateCardActions.RATE_CARD_ASSET_SET_LIST,
  payload: {
    assets,
  },
});
export const onRateCardSetAssetsListLoaded = () => ({
  type: RateCardActions.RATE_CARD_ASSET_SET_LIST_LOADED,
});
export const onRateCardBatchDeleteAssets = () => ({
  type: RateCardActions.RATE_CARD_ASSETS_DELETE_BATCH,
});
export const onRateCardResetAssets = () => ({
  type: RateCardActions.RATE_CARD_RESET_ASSETS,
});
export const onRateCardAdd = (rateCard) => ({
  type: RateCardActions.RATE_CARD_ADD,
  payload: {
    rateCard,
  },
});
export const onRateCardAddReceived = (rateCard) => ({
  type: RateCardActions.RATE_CARD_ADD_RECEIVED,
  payload: {
    rateCard,
  },
});
export const onRateCardDelete = (rateCard) => ({
  type: RateCardActions.RATE_CARD_DELETE,
  payload: {
    rateCard,
  },
});
export const onRateCardClone = (rateCard) => ({
  type: RateCardActions.RATE_CARD_CLONE,
  payload: {
    rateCard,
  },
});
export const onRateCardUpdate = (rateCard) => ({
  type: RateCardActions.RATE_CARD_UPDATE,
  payload: {
    rateCard,
  },
});
export const onRateCardUpdatedReceived = (rateCard) => ({
  type: RateCardActions.RATE_CARD_UPDATED_RECEIVED,
  payload: {
    rateCard,
  },
});
export const onRateCardSetTab = ({ tabName }) => ({
  type: RateCardActions.SET_TAB,
  payload: {
    tabName,
  },
});
export const onRateCardCreateAssets = (rateCardId) => ({
  type: RateCardActions.RATE_CARD_CREATE_ASSETS,
  payload: {
    rateCardId,
  },
});

export const onRateCardAssetAdd = ({ rateCardId, campaign }, isFromBulkImport = false) => ({
  type: RateCardActions.RATE_CARD_ASSET_ADD,
  payload: {
    asset: {
      rateCardId,
      campaign,
    },
    isFromBulkImport,
  },
});
export const onRateCardAssetUpdate = (
  {
    rateCardId,
    campaign,
    id,
    version,
  },
  isFromBulkImport = false,
) => ({
  type: RateCardActions.RATE_CARD_ASSET_UPDATE,
  payload: {
    asset: {
      rateCardId,
      id,
      campaign,
      version,
    },
    isFromBulkImport,
  },
});
export const onRateCardAssetUpdateReceived = ({
  rateCardId, campaign, id, version,
}) => ({
  type: RateCardActions.RATE_CARD_ASSET_UPDATE_RECEIVED,
  payload: {
    asset: {
      rateCardId,
      id,
      campaign,
      version,
    },
  },
});
export const onRateCardAssetDelete = (asset) => ({
  type: RateCardActions.RATE_CARD_ASSET_DELETE,
  payload: {
    asset,
  },
});
export const onRateCardAssetsLoadList = (
  { rateCardId, nextToken = null },
  forceReload = false,
) => ({
  type: RateCardActions.RATE_CARD_ASSET_LOAD_LIST,
  payload: {
    rateCardId,
    nextToken,
    forceReload,
  },
});
export const onRateCardAssetsExport = (reportType) => ({
  type: RateCardActions.RATE_CARD_ASSET_EXPORT,
  payload: {
    reportType,
  },
});
export const onRateCardAssetsPublish = () => ({
  type: RateCardActions.RATE_CARD_ASSETS_PUBLISH,
});
export const onRateCardMarkAsPublished = (rateCardId) => ({
  type: RateCardActions.RATE_CARD_MARK_AS_PUBLISHED,
  payload: {
    rateCardId,
  },
});
export const onRateCardOpenReportModal = (reportType) => ({
  type: RateCardActions.OPEN_REPORT_MODAL,
  payload: {
    reportType,
  },
});
export const onRateCardCloseReportModal = () => ({
  type: RateCardActions.CLOSE_REPORT_MODAL,
});
export const onRateCardSetSearchQuery = (searchQuery) => ({
  type: RateCardActions.SET_SEARCH_QUERY,
  payload: {
    searchQuery,
  },
});
export const onRateCardSetConfigName = (configName) => ({
  type: RateCardActions.SET_CONFIG_NAME,
  payload: {
    configName,
  },
});
export const onRateCardRefreshPlacements = (placementType) => ({
  type: RateCardActions.RATE_CARD_REFRESH_PLACEMENTS,
  payload: {
    placementType,
  },
});