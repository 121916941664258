import { GridViewActions } from 'src/constants';

export const onSetFilters = (data) => ({
  type: GridViewActions.SET_FILTERS,
  data,
});
export const onSetSorts = (data) => ({
  type: GridViewActions.SET_SORTS,
  data,
});
export const onSetActiveFilterView = (data) => ({
  type: GridViewActions.SET_ACTIVE_FILTER_VIEW,
  data,
});
export const onOpenFilterViewModal = (data) => ({
  type: GridViewActions.OPEN_FILTER_VIEW_MODAL,
  data,
});
export const onCloseFilterViewModal = () => ({
  type: GridViewActions.CLOSE_FILTER_VIEW_MODAL,
});
export const onSaveFilterView = (data) => ({
  type: GridViewActions.SAVE_FILTER_VIEW,
  data,
});
export const onDeleteFilterView = (data) => ({
  type: GridViewActions.DELETE_FILTER_VIEW,
  data,
});
export const onLoadFilterViewsFromStorage = () => ({
  type: GridViewActions.LOAD_FILTER_VIEWS_FROM_STORAGE,
});
export const onSetFilterViews = (data) => ({
  type: GridViewActions.SET_FILTER_VIEWS,
  data,
});