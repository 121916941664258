import {
  CreateCsCampaignActions,
  ThirdPartyIFrameActions,
} from 'src/constants';

export const onThirdPartyIFrameSetDestination = (destination) => ({
  type: ThirdPartyIFrameActions.SET_DESTINATION,
  payload: destination,
});
export const onThirdPartyIFrameClose = () => ({
  type: ThirdPartyIFrameActions.CLOSE,
});
export const onThirdPartyIFrameEnableExperience = () => ({
  type: ThirdPartyIFrameActions.ENABLE_EXPERIENCE,
});
export const onThirdPartyIFrameOpenComposerForCampaign = (id, packageId) => ({
  type: ThirdPartyIFrameActions.OPEN_COMPOSER,
  payload: {
    id,
    packageId
  },
});
export const onThirdPartyIFrameOpenMelodyForCampaign = (id, packageId) => ({
  type: ThirdPartyIFrameActions.OPEN_MELODY,
  payload: {
    id,
    packageId
  },
});
export const onThirdPartyIFrameOpenJSONBourneForCampaign = (id, packageId) => ({
  type: ThirdPartyIFrameActions.OPEN_JSON_BOURNE,
  payload: {
    id,
    packageId
  },
});
export const onThirdPartyIFrameSetId = (id, packageId) => ({
  type: ThirdPartyIFrameActions.SET_ID,
  payload: {
    id,
    packageId
  },
});
export const onThirdPartyIFrameEvent = (event) => ({
  type: ThirdPartyIFrameActions.COMPOSER_EVENT,
  payload: event,
});
export const onThirdPartyIFrameMelodyEvent = (event) => ({
  type: ThirdPartyIFrameActions.MELODY_EVENT,
  payload: event,
});
export const onThirdPartyIFrameJSONBourneEvent = (event) => ({
  type: ThirdPartyIFrameActions.JSON_BOURNE_EVENT,
  payload: event,
});
export const onCreateCsCampaignClearData = () => ({
  type: CreateCsCampaignActions.CLEAR_DATA,
});
export const onCreateCsCampaignCloseModal = () => ({
  type: CreateCsCampaignActions.CLOSE_MODAL,
});
export const onCreateCsCampaignOpenDiffModal = () => ({
  type: CreateCsCampaignActions.OPEN_DIFF_MODAL,
});
export const onCreateCsCampaignCloseDiffModal = () => ({
  type: CreateCsCampaignActions.CLOSE_DIFF_MODAL,
});
export const onCreateCsCampaignClearValidationErrors = () => ({
  type: CreateCsCampaignActions.CLEAR_VALIDATION_ERRORS,
});
export const onCreateCsCampaignSendCampaign = (data) => ({
  type: CreateCsCampaignActions.SEND_CAMPAIGN,
  data,
});
export const onCreateCsCampaignSetCampaignData = (data) => ({
  type: CreateCsCampaignActions.SET_CAMPAIGN_DATA,
  data,
});
export const onCreateCsCampaignSetCampaign = (campaign) => ({
  type: CreateCsCampaignActions.SET_CAMPAIGN,
  payload: {
    campaign,
  }
});
export const onCreateCsCampaignSetMetadata = (data) => ({
  type: CreateCsCampaignActions.SET_METADATA,
  data,
});
export const onCreateCsCampaignOpenModal = () => ({
  type: CreateCsCampaignActions.OPEN_MODAL,
});
export const onCreateCsCampaignSetRunningStatus = (data) => ({
  type: CreateCsCampaignActions.SET_RUNNING_STATE,
  data,
});
export const onCreateCsCampaignSetValidationErrors = (data) => ({
  type: CreateCsCampaignActions.SET_VALIDATION_ERRORS,
  data,
});
export const onCreateCsCampaignCalcDiff = ({ existingCampaign, updatedCampaign }) => {
  return {
    type: CreateCsCampaignActions.CALC_CAMPAIGN_DIFF,
    data: {
      existingCampaign,
      updatedCampaign,
    },
  };
};
export const onCreateCsCampaignChangeDiffViewMode = (viewMode) => ({
  type: CreateCsCampaignActions.ON_CHANGE_DIFF_VIEW_MODE,
  data: viewMode,
});
export const onCreateCsCampaignSetDiff = (diff) => ({
  type: CreateCsCampaignActions.SET_CAMPAIGN_DIFF,
  data: diff,
});