import {
  ON_METADATA_RECEIVED,
  ON_UPDATE_METADATA,
  MetricLocations,
  MetricNames,
  CampaignColumns,
  WeblabTreatmentTypes,
} from 'src/constants';
import {
  onUpdateMetadataAction,
  onOpenToast,
  onFetchMetadataAction,
  loadCampaigns,
  loadPackages,
  setCampaignsLoadingStarted,
  setPackagesLoadingStarted,
} from 'src/actionCreators';
import window from '../../helpers/window';
import getAllMetadataOptionsForColumn from '../../helpers/getAllMetadataOptionsForColumn';
import { submitAppSyncError, submitTimerMetric } from '../../metrics';
import { joinBusinessStartYear } from '../../helpers/common';
import { getPackageLoadingStatus } from '../../selectors/package';

const getDynamicMetadataFields = (metadata) => {
  return {
    [CampaignColumns.PAGE_THEME.name]: {
      dependsOn: [],
      options: getAllMetadataOptionsForColumn(
        { Meta: { metadata } },
        CampaignColumns.PAGE_NAME.name,
      ),
    },
    [CampaignColumns.WEBLAB_TREATMENT.name]: {
      dependsOn: [],
      options: Object.values(WeblabTreatmentTypes),
    },
  };
};

export const getProcessedMetadata = (metadata) => {
  return {
    ...metadata,
    fields: {
      ...metadata.fields,
      ...getDynamicMetadataFields(metadata),
    },
  };
};

export default ({ getState, dispatch }) => (next) => (action) => {
  const { type } = action;

  if (type === ON_METADATA_RECEIVED) {
    const {
      Meta: { initialMetadataLoaded },
      Sitewide: { selectedBusiness, startYears },
    } = getState();
    if (!initialMetadataLoaded) {
      submitTimerMetric(MetricNames.INITIAL_METADATA, Math.floor(window.performance.now()));
    }
    try {
      const { data: { data: { getMetadata: { metadata, version } } } } = action;
      const data = {
        ...getProcessedMetadata(JSON.parse(metadata)),
        version,
      };
      const newAction = {
        ...action,
        data,
      };
      next(newAction);
      const packageLoadingStatus = getPackageLoadingStatus(getState());
      startYears.forEach((startYear) => {
        const businessStartYear = joinBusinessStartYear(selectedBusiness, startYear);
        const packageLoadedForBusinessStartYear = packageLoadingStatus.hasOwnProperty(businessStartYear) && !packageLoadingStatus[businessStartYear];
        if (startYear && !packageLoadedForBusinessStartYear) {
          dispatch(setPackagesLoadingStarted(businessStartYear));
          dispatch(loadPackages(selectedBusiness, startYear));
          dispatch(setCampaignsLoadingStarted(businessStartYear));
          dispatch(loadCampaigns(selectedBusiness, startYear));
        }
      })
      return;
    } catch (e) {
      submitAppSyncError(e, { location: MetricLocations.METADATA });
      dispatch(onOpenToast('Error parsing metadata. Please try refreshing the page.'));
      return null;
    }
  }

  if (type === ON_UPDATE_METADATA) {
    const {
      Meta: { metadata },
      Sitewide: { selectedBusiness },
      User: { currentUser },
    } = getState();
    const username = currentUser ? currentUser.username : null;
    const { version: currentVersion } = metadata;
    const onFailureFunction = () => {
      dispatch(onOpenToast('Error updating metadata. Please try refreshing the page.', 0));
    };
    const onSuccessFunction = () => {
      dispatch(onFetchMetadataAction(selectedBusiness));
      dispatch(onOpenToast('Successfully edited metadata.'));
    };

    const version = currentVersion + 1;
    dispatch(onUpdateMetadataAction(
      username,
      selectedBusiness,
      JSON.stringify(metadata),
      version,
      onFailureFunction,
      onSuccessFunction,
    ));
  }

  return next(action);
};
