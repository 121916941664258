import {
  GraphQLLabels, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphql';
import { renewUserSession } from '../../graphql/mutations';
import { submitAppSyncError, submitError } from '../../metrics';

export default (dispatch, getState) => {
  const { User: { currentUser: { username } } } = getState();

  const metricContext = {
    location: MetricLocations.NOTIFICATIONS_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: {
      username,
    },
    action: MetricNames.UPDATE_NOTIFICATIONS_LAST_SEEN_TIME,
    username,
  };

  dispatch(onGraphQLAction({
    graphqlAction: renewUserSession,
    data: {
      input: {
        username,
      },
    },
    label: GraphQLLabels.RENEW_USER_SESSION,
    onSuccessFunction({ data: { renewUserSession: userSessionData } }) {
      if (!userSessionData) {
        submitError('renewUserSession returned an empty result', metricContext);
      }
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));
};
