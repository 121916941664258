import { getDependentOptionsForInStoreUtilization } from '../../helpers/getMetadataOptions';
import { CampaignColumns, PlacementType } from '../../constants';
import { getTranslatedOptionsList } from '../../helpers/common';
import {
  getOptionsByName,
  getCaseInsensitiveSingleOptionByKey,
  getGroupByCategories,
} from '../../helpers/instoreUtilization';
import {
  InStoreUtilizationFilterGroupNames,
  InStoreUtilizationPlacementTypes,
} from '../../constants/inStoreUtilization';
import {
  onInStoreUtilizationLoadPresets,
  onInStoreUtilizationSetFilterOptions,
} from 'src/actionCreators';
import { filterInStoreStatusOptions } from '../../helpers/filterInStoreStatuses';

const getStatusOptions = (metadataOptions) => {
  const sortedInStoreStatuses = filterInStoreStatusOptions(
    getOptionsByName(
      CampaignColumns.STATUS.name,
      metadataOptions,
    ),
  ).sort();

  return getTranslatedOptionsList(sortedInStoreStatuses);
};

const getVerticalOptions = (metadataOptions) => getTranslatedOptionsList(
  getOptionsByName(
    CampaignColumns.VERTICAL.name,
    metadataOptions,
  ),
);

const getPlacementOptions = (metadataOptions) => {
  const placements = getCaseInsensitiveSingleOptionByKey(
    getOptionsByName(
      CampaignColumns.PLACEMENT.name,
      metadataOptions,
    ),
    PlacementType.IN_STORE,
  );
  const groupByCategories = getGroupByCategories();
  const filteredPlacements = placements.filter((placement) => {
    return !groupByCategories.find((groupByCategory) => {
      return placement.trim().toLowerCase().includes(groupByCategory.toLowerCase());
    });
  });
  const sortedPlacementsWithGroupByCategory = [
    ...filteredPlacements,
    ...groupByCategories,
  ].sort();

  return getTranslatedOptionsList(sortedPlacementsWithGroupByCategory);
};

const getPlacementTypeOptions = () => {
  const convertedPlacementTypes = Object.keys(InStoreUtilizationPlacementTypes).map((key) => {
    return InStoreUtilizationPlacementTypes[key].name;
  });

  return getTranslatedOptionsList(convertedPlacementTypes);
};

export default (dispatch, getState, action, next) => {
  const {
    Meta: {
      metadata,
    },
  } = getState();

  const metadataOptions = getDependentOptionsForInStoreUtilization(metadata, [
    CampaignColumns.STATUS.name,
    CampaignColumns.VERTICAL.name,
    CampaignColumns.PLACEMENT.name,
  ]);

  const filterOptions = {
    [InStoreUtilizationFilterGroupNames.STATUS.name]: getStatusOptions(metadataOptions),
    [InStoreUtilizationFilterGroupNames.VERTICAL.name]: getVerticalOptions(metadataOptions),
    [InStoreUtilizationFilterGroupNames.PLACEMENT.name]: getPlacementOptions(metadataOptions),
    [InStoreUtilizationFilterGroupNames.PLACEMENT_TYPES.name]: getPlacementTypeOptions(),
  };

  dispatch(onInStoreUtilizationSetFilterOptions(filterOptions));
  dispatch(onInStoreUtilizationLoadPresets());

  return next(action);
};
