import {
  GraphQLLabels, MetricTypes, MetricNames,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphql';
import { updateUserSetting } from '../../graphql/mutations';
import { submitAppSyncError, submitStringMetric } from '../../metrics';

export default (dispatch, getState, action, next, payload) => {
  const { User: { currentUser: { username } } } = getState();

  const { dataType, data, metricLocation } = payload;

  const getUserSettingInput = () => {
    return {
      input: {
        userSetting: {
          username,
          dataType,
          settings: data,
        }
      }
    };
  }

  const metricContext = {
    location: metricLocation,
    type: MetricTypes.APPSYNC,
    data: getUserSettingInput(),
    action: MetricNames.UPDATE_USER_SETTING,
    username,
  };

  submitStringMetric(MetricNames.UPDATE_USER_SETTING, metricContext);

  dispatch(onGraphQLAction({
    graphqlAction: updateUserSetting,
    data: getUserSettingInput(),
    label: GraphQLLabels.UPDATE_USER_SETTING,
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
