import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import history from '../history';
import AuditLog from './AuditLog';
import BulkImport from './BulkImport';
import BulletinBoard from './BulletinBoard';
import Comments from './Comments';
import ContentSymphony from './ThirdPartyIntegrations/ContentSymphony';
import CustomView from './CustomView';
import Dashboard from './Dashboard';
import FormView from './FormView';
import GridViewPage from './GridViewPage';
import InStoreUtilizationPage from './InStoreUtilizationPage';
import Meta from './Metadata';
import MetadataAuditLog from './MetadataAuditLog';
import MetadataEditor from './MetadataEditor';
import Notifications from './CustomerNotifications';
import PackageTabs from './PackageTabs';
import PerformanceTimer from './PerformanceTimer';
import RateCard from './RateCard';
import Sitewide from './Sitewide';
import ThirdPartyIFrame from './ThirdPartyIntegrations/ThirdPartyIFrame';
import Timer from './Timer';
import User from './User';
import UserSettings from './UserSettings';
import UtilizationPage from './UtilizationPage';

export default combineReducers({
  router: connectRouter(history),
  form: reduxFormReducer,
  GridViewPage,
  UtilizationPage,
  InStoreUtilizationPage,
  FormView,
  Sitewide,
  User,
  ContentSymphony,
  Dashboard,
  MetadataEditor,
  BulkImport,
  BulletinBoard,
  AuditLog,
  CustomView,
  UserSettings,
  ThirdPartyIFrame,
  Notifications,
  Comments,
  PackageTabs,
  RateCard,
  Timer,
  PerformanceTimer,
  MetadataAuditLog,
  Meta,
});
