import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import {
  MessageEventTypes,
  LandingTypes,
  QueryParseTypes,
  CampaignColumns,
} from '../../constants';
import window from '../../helpers/window';

const getParsedQueryValueArray = (search, name) => {
  const values = qs.parse(search)[name];
  if (!values) {
    return [];
  }
  if (Array.isArray(values)) {
    return values;
  }

  return [values];
};

const getParsedQueryValueString = (search, name) => {
  const string = qs.parse(search)[name];
  if (!string || typeof string !== 'string') {
    return '';
  }

  return string;
};

const getParsedQueryValue = (search, name, parseType = QueryParseTypes.STRING) => {
  if (parseType === QueryParseTypes.ARRAY) {
    return getParsedQueryValueArray(search, name);
  }

  return getParsedQueryValueString(search, name);
};

const parseComposerParams = (searchString) => {
  try {
    let search = searchString;
    if (search[0] === '?') {
      search = search.substring(1);
    }
    return {
      files: getParsedQueryValue(search, 'uploadedFiles', QueryParseTypes.ARRAY),
      [CampaignColumns.COMPOSER_COMPONENT_JSON.name]: getParsedQueryValue(
        search,
        CampaignColumns.COMPOSER_COMPONENT_JSON.name,
      ),
    };
  } catch (e) {
    return {};
  }
};

const parseJSONBourneParams = (searchString) => {
  try {
    let search = searchString;
    if (search[0] === '?') {
      search = search.substring(1);
    }
    const params = qs.parse(search);
    return params
      ? {
        widgetParameters: params.widgetParameters,
        percolateParameters: params.percolateParameters,
      }
      : '';
  } catch (e) {
    return '';
  }
};

const handleComposer = (props) => {
  const {
    location: { search },
  } = props;
  let params = [];
  if (search) {
    params = parseComposerParams(search);
  }
  window.parent.postMessage({ type: MessageEventTypes.COMPOSER, data: params }, '*');
};

const handleJSONBourne = (props) => {
  const {
    location: { search },
  } = props;

  window.parent.postMessage({ type: MessageEventTypes.JSON_BOURNE, data: parseJSONBourneParams(search) }, '*');
};

const Redirecter = (props) => {
  const {
    match: {
      params: { landingType },
    },
  } = props;
  useEffect(() => {
    switch (landingType) {
      case LandingTypes.JSON_BOURNE: {
        return handleJSONBourne(props);
      }
      case LandingTypes.COMPOSER: {
        return handleComposer(props);
      }
      default: {
        return handleComposer(props);
      }
    }
  }, [landingType, props]);
  return (
    <div>
      Successfully redirected from external tool, please wait while we update the campaign.
    </div>
  );
};

Redirecter.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      landingType: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Redirecter;
