import { AuditLogActions, AuditLogTypes } from 'src/constants';

export const onAuditLogSetItem = (id, auditLogType = AuditLogTypes.CAMPAIGN) => ({
  type: AuditLogActions.SET_ITEM,
  payload: {
    id,
    auditLogType,
  },
});
export const onAuditLogFetch = (id, auditLogType) => ({
  type: AuditLogActions.FETCH,
  payload: {
    id,
    auditLogType,
  },
});
export const onAuditLogClear = () => ({
  type: AuditLogActions.CLEAR,
});
export const onAuditLogSetLoading = (isLoading) => ({
  type: AuditLogActions.SET_LOADING,
  payload: isLoading,
});
export const onAuditLogSetLogs = (data) => ({
  type: AuditLogActions.SET_LOGS,
  payload: { data },
});
export const onAuditLogSetUntranslatedLogs = (data) => ({
  type: AuditLogActions.SET_UNTRANSLATED_LOGS,
  payload: { data },
});
export const onAuditLogClearUntranslatedLogs = () => ({
  type: AuditLogActions.CLEAR_UNTRANSLATED_LOGS,
});