import { UtilizationActions } from 'src/constants';

export const onUtilizationFilterChange = (filterOptionKey, filterOptionValue, selectedFilter) => ({
  type: UtilizationActions.FILTER_CHANGE,
  data: {
    filterOptionKey,
    filterOptionValue,
    selectedFilter,
  },
});
export const onUtilizationBusinessChanged = (data) => ({
  type: UtilizationActions.BUSINESS_CHANGED,
  data,
});
export const onUtilizationSelectDateRange = (data) => ({
  type: UtilizationActions.SELECT_DATE_RANGE,
  data,
});
export const onUtilizationSelectTimezone = (data) => ({
  type: UtilizationActions.SELECT_TIMEZONE,
  data,
});
export const onUtilizationShowResults = () => ({
  type: UtilizationActions.SHOW_RESULTS,
});
export const onUtilizationSetResults = (data) => ({
  type: UtilizationActions.SET_RESULTS,
  data,
});
export const onUtilizationOpenFiltersModal = (data) => ({
  type: UtilizationActions.OPEN_FILTERS_MODAL,
  data,
});
export const onUtilizationCloseFiltersModal = (data) => ({
  type: UtilizationActions.CLOSE_FILTERS_MODAL,
  data,
});
export const onUtilizationStartTimeSet = ({ startTime }) => ({
  type: UtilizationActions.SELECT_START_TIME,
  data: {
    startTime,
  },
});
export const onUtilizationStopTimeSet = ({ endTime }) => ({
  type: UtilizationActions.SELECT_END_TIME,
  data: {
    endTime,
  },
});
export const onUtilizationToggleStatsMode = () => ({
  type: UtilizationActions.TOGGLE_STATS_MODE,
});
export const onUtilizationToggleAbsSovMode = () => ({
  type: UtilizationActions.TOGGLE_ABS_SOV_MODE,
});
export const onUtilizationSetSlotsOrder = (data) => {
  const {
    business,
    pageType,
    slots,
    slotType,
  } = data;

  return {
    type: UtilizationActions.SET_SLOTS_ORDER,
    data: {
      business,
      pageType,
      slots,
      slotType,
    },
  };
};
export const onUtilizationToggleSlotsSettings = () => ({
  type: UtilizationActions.TOGGLE_SLOTS_ORDER_SETTINGS,
});
export const onUtilizationSwitchViewMode = (viewMode) => ({
  type: UtilizationActions.SWITCH_VIEW_MODE,
  payload: {
    viewMode,
  },
});
export const onUtilizationShowFmcSlots = (flag) => ({
  type: UtilizationActions.SHOW_FMC_SLOTS,
  payload: {
    flag,
  },
});
export const onUtilizationSetSlotsViewMode = (slotsViewMode) => ({
  type: UtilizationActions.SET_SLOTS_VIEW_MODE,
  payload: {
    slotsViewMode,
  },
});