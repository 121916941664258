import _ from 'lodash';
import moment from 'moment-timezone';
import {
  CampaignNamePrefixes,
  TargetBusinessPrefixes,
  TargetBusinessTypes,
  DateFormats,
  Delimiters,
  WidgetTypes,
} from '../constants';
import {
  getItemBusiness,
  isFreshOnsiteTraffic,
} from './common';
import { isOnlineCampaign } from './campaign';

const translatePlacement = (placementName) => {
  if (!placementName) {
    return '';
  }

  const normalizedName = placementName.toLowerCase();
  if (normalizedName === WidgetTypes.HERO_MAIN.toLowerCase()) {
    return normalizedName;
  }

  const [value] = normalizedName.split(' ');

  const RULES = {
    shoveler: 'shov',
    seasonal: 'shov',
    private: 'shov',
    other: 'othr',
    impulse: 'impw',
  };

  return RULES[value] ? RULES[value] : value;
};

const translateVertical = (value) => {
  const newValue = value.substring(0, 3);

  const EXCEPTIONS_LIST = {
    BES: 'BOA',
  };

  const valueFromExceptionList = EXCEPTIONS_LIST[newValue.toUpperCase()];
  if (valueFromExceptionList) {
    return valueFromExceptionList;
  }

  return newValue;
};

const translateStartDate = (statDate) => {
  if (!statDate) {
    return '';
  }

  return moment(statDate).format(DateFormats.MMDD);
};

const removeSpecialChars = (campaignName) => {
  if (!campaignName) {
    return '';
  }
  // eslint-disable-next-line no-useless-escape
  return campaignName.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, Delimiters.UNDERSCORE);
};

export const getCampaignNamePrefix = (business) => {
  if (business in CampaignNamePrefixes) {
    return CampaignNamePrefixes[business];
  }

  return CampaignNamePrefixes.DEFAULT;
};

export const getTrafficCampaignNamePrefix = (targetBusiness) => {
  return TargetBusinessPrefixes[targetBusiness] || TargetBusinessPrefixes[TargetBusinessTypes.AFO];
};

const getLanguageCodeByName = (language) => {
  if (!language) {
    return '';
  }

  switch(language) {
    case 'English': {
      return 'EN';
    }
    case 'Spanish': {
      return 'ES';
    }
    default:
      return language.substring(0, 2).toUpperCase();
  }
};

const getCampaignNamePostfix = (campaign) => {
  const { language, campaignType } = campaign;
  const campaignTypePrefix = campaignType.substring(0, 2).toUpperCase();

  if (isOnlineCampaign(campaign)) {
    const languageCode = getLanguageCodeByName(language);
    if (languageCode) {
      return [
        campaignTypePrefix,
        languageCode,
      ].join(Delimiters.UNDERSCORE);
    }

    return campaignTypePrefix;
  }

  return campaignTypePrefix;
};

export const formatInput = (input) => {
  if (!input) {
    return '';
  }

  return input.replace(/[^a-zA-Z0-9\s_]/g, '').trim().replace(/[\s_]+/g, '_');
}

export const getGeneratedCampaignName = (campaign, pkg) => {
  if (_.isEmpty(campaign)) {
    return '';
  }

  const business = getItemBusiness(campaign);
  if (!business) {
    return '';
  }

  const isFreshUsTrafficCampaign = isFreshOnsiteTraffic(business);
  const isOnline = isOnlineCampaign(campaign);

  const {
    device = '',
    seasonalEvent = '',
    startDate = '',
    placement = '',
    pageName = '',
    widget = '',
    targetBusiness = '',
  } = campaign;

  const { vertical = '', vendor = '', program = '' } = pkg;

  const campaignNameParts = [
    ...(isFreshUsTrafficCampaign
        ? [
          formatInput(program),
          translateStartDate(startDate),
          translatePlacement(placement),
          formatInput(device),
          formatInput(seasonalEvent),
        ]
        : [
          translateVertical(vertical),
          formatInput(vendor),
          ...(isOnline
              ? [formatInput(pageName), formatInput(widget)]
              : [translatePlacement(placement)]
          ),
          translateStartDate(startDate),
          getCampaignNamePostfix(campaign),
        ]
    ),
  ]
  .filter(Boolean)
  .map((part) => removeSpecialChars(part).toUpperCase());

  return [
    isFreshUsTrafficCampaign ? getTrafficCampaignNamePrefix(targetBusiness) : getCampaignNamePrefix(business),
    ...campaignNameParts,
  ].join(Delimiters.UNDERSCORE);
};

