import { LoadingStatusActions } from 'src/constants';

export const setCampaignsLoadingStarted = (businessStartYear) => ({
  type: LoadingStatusActions.SET_CAMPAIGNS_LOADING_STATUS,
  payload: { businessStartYear, isLoading: true },
});
export const setCampaignsLoadingFinished = (businessStartYear) => ({
  type: LoadingStatusActions.SET_CAMPAIGNS_LOADING_STATUS,
  payload: { businessStartYear, isLoading: false },
});
export const setPackagesLoadingStarted = (businessStartYear) => ({
  type: LoadingStatusActions.SET_PACKAGES_LOADING_STATUS,
  payload: { businessStartYear, isLoading: true },
});
export const setPackagesLoadingFinished = (businessStartYear) => ({
  type: LoadingStatusActions.SET_PACKAGES_LOADING_STATUS,
  payload: { businessStartYear, isLoading: false },
});