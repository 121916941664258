import { connect } from 'react-redux';
import App from './app';
import {
  onPageInit,
  onUpdatedCampaignSubscriptionReceived,
  onOpenToast,
  onThirdPartyIFrameEvent,
  onThirdPartyIFrameJSONBourneEvent,
  onUpdateCommentsSubscriptionRecieved,
  onCreateNotificationSubscriptionReceived,
  onThirdPartyIFrameMelodyEvent,
} from 'src/actionCreators';
import { MessageEventTypes } from 'src/constants';
import { setAppSyncConnectionStatus } from 'src/actionCreators';
import { AppSyncConnectionType } from 'src/constants/appSync';
import './App.css';

export const mapStateToProps = (state) => {
  const { ThirdPartyIFrame: { composerEnabled } } = state;
  return {
    composerEnabled,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onPageInit() {
      dispatch(onPageInit());
    },
    onUpdateCampaign(campaignData) {
      dispatch(onUpdatedCampaignSubscriptionReceived(campaignData));
    },
    onUpdateComments(commentData) {
      dispatch(onUpdateCommentsSubscriptionRecieved(commentData));
    },
    onCreateNotification(notificationData) {
      dispatch(onCreateNotificationSubscriptionReceived(notificationData));
    },
    onOpenToast(message) {
      dispatch(onOpenToast(message, 0));
    },
    onThirdPartyIFrameEvent(event) {
      // Output all response from third party event for debugging purpose
      if(!(/^react-devtools/gi).test(event.data.source)) {
        console.log(event);
      }
      if (event.data.type === MessageEventTypes.COMPOSER) {
        dispatch(onThirdPartyIFrameEvent(event.data.data));
      }
      if (event.data.type === MessageEventTypes.JSON_BOURNE) {
        dispatch(onThirdPartyIFrameJSONBourneEvent(event.data.data));
      }
      if (event.data.data === MessageEventTypes.MELODY) {
        dispatch(onThirdPartyIFrameMelodyEvent(event.data));
      }
    },
    onAppSyncSubscriptionConnectionLost(error) {
      dispatch(setAppSyncConnectionStatus(
        false,
        AppSyncConnectionType.SUBSCRIPTION,
        error
      ));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
