import { connect } from 'react-redux';
import FullSlotWarning from './FullSlotWarning';
import { FormValidationTypes } from '../../../../../../constants';

export const mapStateToProps = (state) => {
  const {
    FormView: {
      utilization: utilizationData,
      validation: {
        [FormValidationTypes.UTILIZATION]: {
          isValidDesktopSlot,
          isValidMobileSlot,
        },
      },
    },
  } = state;

  const showErrors = !!(!isValidDesktopSlot || !isValidMobileSlot);

  return {
    utilizationData,
    showErrors,
  };
};

export default connect(mapStateToProps)(FullSlotWarning);
