import _ from 'lodash';
import {
  onInStoreUtilizationSetResults,
} from 'src/actionCreators';
import { calculateInStoreUtilization } from '../../selectors/inStoreUtilization';
import { InStoreUtilizationConfig } from '../../constants/inStoreUtilization';

export default (dispatch, getState, action, next) => {
  _.defer(() => {
    dispatch(onInStoreUtilizationSetResults(calculateInStoreUtilization(getState())));
  }, InStoreUtilizationConfig.CALCULATE_UTILIZATION_DEFER_TIMEOUT);

  return next(action);
};
