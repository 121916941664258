import {
  GraphQLLabels, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphql';
import { deleteRateCard } from '../../graphql/mutations';
import { submitAppSyncError, submitError } from '../../metrics';
import { localToAppsync } from '../../helpers/translateRateCard';

export default (dispatch, getState, action, next) => {
  const {
    Sitewide: { selectedBusiness },
    User: { currentUser: { username } },
  } = getState();
  const { payload: { rateCard } } = action;

  const metricContext = {
    location: MetricLocations.RATE_CARD_DELETE_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.DELETE_RATE_CARD,
    username,
  };

  const input = {
    rateCard: {
      ...localToAppsync(rateCard),
      business: selectedBusiness,
      version: rateCard.version,
    },
    username,
  };

  dispatch(onGraphQLAction({
    graphqlAction: deleteRateCard,
    data: {
      input,
    },
    label: GraphQLLabels.DELETE_RATE_CARD,
    onSuccessFunction({ data: { deleteRateCard: response } }) {
      if (!response) {
        submitError('deleteRateCard returned an empty result', metricContext);
      }
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
