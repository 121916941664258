import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import history from '../history';
import reducers from '../reducers';
import App from '../middleware/App';
import AuditLog from '../middleware/AuditLog';
import BulletinBoard from '../middleware/BulletinBoard';
import BusinessSelector from '../middleware/BusinessSelector';
import Comments from '../middleware/Comments';
import CustomView from '../middleware/CustomView';
import CustomerNotifications from '../middleware/CustomerNotifications';
import ContentSymphony from '../middleware/ThirdPartyIntegrations/ContentSymphony';
import Dashboard from '../middleware/Dashboard';
import Export from '../middleware/Export';
import FlashFill from '../middleware/FlashFill';
import GraphQl from '../middleware/GraphQl';
import GridViewFilter from '../middleware/GridViewFilter';
import GridViewKeyboardListener from '../middleware/GridViewKeyboardListener';
import GridViewPage from '../middleware/GridViewPage';
import InStoreUtilizationPage from '../middleware/InStoreUtilizationPage';
import Metadata from '../middleware/Metadata';
import MetadataAuditLog from '../middleware/MetadataAuditLog';
import MetadataEditor from '../middleware/MetadataEditor';
import MigrateSettings from '../middleware/MigrateSettings';
import Notifications from '../middleware/Notifications';
import PerformanceTimer from '../middleware/PerformanceTimer';
import RateCard from '../middleware/RateCard';
import StartYearsSelector from '../middleware/StartYearsSelector';
import ThirdPartyIFrame from '../middleware/ThirdPartyIntegrations/ThirdPartyIFrame';
import Timer from '../middleware/Timer';
import UsageTracker from '../middleware/UsageTracker';
import User from '../middleware/User';
import UtilizationPage from '../middleware/UtilizationPage';
import BulkImport from '../middleware/BulkImport';
import FormView from '../middleware/FormView';

// Don't change the order of the middleware unless you know what you're doing as
// it will affect the app's behavior
const middleware = [
  BusinessSelector,
  StartYearsSelector,
  App,
  Export,
  GridViewKeyboardListener,
  GridViewPage,
  GridViewFilter,
  GraphQl,
  Metadata,
  FormView,
  Notifications,
  UtilizationPage,
  InStoreUtilizationPage,
  User,
  ContentSymphony,
  Dashboard,
  MetadataEditor,
  BulkImport,
  BulletinBoard,
  AuditLog,
  CustomView,
  ThirdPartyIFrame,
  CustomerNotifications,
  Comments,
  MetadataAuditLog,
  Timer,
  PerformanceTimer,
  FlashFill,
  RateCard,
  MigrateSettings,
  routerMiddleware(history),
  UsageTracker,
];

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  middleware.push(createLogger());
}

const store = createStore(
  reducers,
  applyMiddleware(...middleware),
);

export default store;
