import {
  GraphQLLabels,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import {
  onRateCardAddReceived,
} from '../../actionCreators';
import onGraphQLAction from '../../actionCreators/graphql';
import { createRateCard } from '../../graphql/mutations';
import { submitAppSyncError, submitError } from '../../metrics';

import { appsyncToLocal, localToAppsync } from '../../helpers/translateRateCard';

export default (dispatch, getState, action, next) => {
  const {
    User: { currentUser: { username } },
    Sitewide: { selectedBusiness },
  } = getState();
  const {
    payload: {
      rateCard,
    },
  } = action;

  const metricContext = {
    location: MetricLocations.RATE_CARD_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.ADD_RATE_CARD,
    username,
  };

  const input = {
    rateCard: {
      ...localToAppsync(rateCard),
      business: selectedBusiness,
      version: 1,
    },
    username,
  };

  dispatch(onGraphQLAction({
    graphqlAction: createRateCard,
    data: {
      input,
    },
    label: GraphQLLabels.CREATE_RATE_CARD,
    onSuccessFunction({ data: { createRateCard: response } }) {
      if (!response) {
        submitError('createRateCard returned an empty result', metricContext);
        return;
      }
      const translatedLocalRateCard = appsyncToLocal(response);

      dispatch(onRateCardAddReceived(translatedLocalRateCard));
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
