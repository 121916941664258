import {
  ThirdPartyIFrameActions,
} from 'src/constants';

import { openComposer, handleComposerEvent, openMelody, handleMelodyEvent } from './composer';
import { openJSONBourne, handleJSONBourneEvent } from './JSONBourne';

export default ({ getState, dispatch }) => (next) => (action) => {
  if (action.type === ThirdPartyIFrameActions.OPEN_COMPOSER) {
    openComposer({ getState, dispatch, action });
  }
  if (action.type === ThirdPartyIFrameActions.OPEN_MELODY) {
    openMelody({ getState, dispatch, action });
  }
  if (action.type === ThirdPartyIFrameActions.COMPOSER_EVENT) {
    handleComposerEvent({ getState, dispatch, action });
  }
  if (action.type === ThirdPartyIFrameActions.MELODY_EVENT) {
    handleMelodyEvent({ getState, dispatch, action });
  }
  if (action.type === ThirdPartyIFrameActions.OPEN_JSON_BOURNE) {
    openJSONBourne({ getState, dispatch, action });
  }
  if (action.type === ThirdPartyIFrameActions.JSON_BOURNE_EVENT) {
    handleJSONBourneEvent({ getState, dispatch, action });
  }

  return next(action);
};
