/* eslint-disable consistent-return */
import {
  MetricNames,
  MetricTypes,
  ON_MIGRATE_LOCAL_TO_CLOUD,
} from '../../constants';
import { submitAppSyncError } from '../../metrics';
import updateUserSettings from 'src/middleware/UserSettings/updateUserSettings';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  if (action.type === ON_MIGRATE_LOCAL_TO_CLOUD) {
    const { data } = action;
    const payload = {
      metricLocation: data.location,
      dataType: data.type,
      data: data.setting,
    };
    updateUserSettings(dispatch, getState, action, next, payload);
  }

  return next(action);
};

export const checkLocalStorage = (dataType, metricLocation) => {
  try {
    const localStorageSetting = localStorage.getItem(dataType) || null;
    return localStorageSetting;
  } catch (error) {
    const metricContext = {
      location: metricLocation,
      type: MetricTypes.APPSYNC,
      action: MetricNames.GET_USER_SETTING,
    };
    submitAppSyncError(error, metricContext);
  }
};

export const getDefaultFiltersAndSorts = () => {
  return [{
    filters: [],
    sorts: [],
  }];
};

export const updateLocalStorage = (dataType, data) => {
  return localStorage.setItem(dataType, data);
};
