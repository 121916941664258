import { connect } from 'react-redux';
import {
  onCreateCsCampaignCloseModal,
  onCreateCsCampaignClearValidationErrors,
  onCreateCsCampaignSendCampaign,
} from 'src/actionCreators';
import ContentSymphonyModal from './ContentSymphonyModal';

export const mapStateToProps = (state) => {
  const {
    ContentSymphony: {
      isModalOpen,
      creativeData,
      placementData,
      validationErrors,
      isRunning,
      isCreateCsCampaignEnabled,
    },
  } = state;

  const { owner = '', displayName = '' } = creativeData;
  return {
    isOpen: isModalOpen,
    owner,
    displayName,
    creativeData,
    placementData,
    validationErrors,
    isRunning,
    isCreateCsCampaignEnabled,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal() {
      dispatch(onCreateCsCampaignCloseModal());
      dispatch(onCreateCsCampaignClearValidationErrors());
    },
    onCreate(campaignData) {
      dispatch(onCreateCsCampaignSendCampaign(campaignData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentSymphonyModal);
