import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import closeLargeTokens from '@amzn/meridian-tokens/base/icon/close-large';
import styles from './Package.module.scss';
import PackageHeader from './PackageHeader';
import PackageForm from '../PackageForm';
import CommentsList from '../CommentsList';
import history from '../../history';
import {
  Urls,
  Language,
  nonOmnichannelPackageTabList,
} from '../../constants';

const Package = (props) => {
  const {
    isNew,
    isClone,
    isLoading,
    isAssetEditMode,
    isOmnichannelMode,
    loadedPackage,
    onSetActiveCampaign,
    onStartCommentsPolling,
    onStopCommentsPolling,
    onResetComments,
    onSwitchOmnichannelTab,
    shouldShowOnCloseConfirmation,
    campaignId,
    currentTab,
    defaultOnlineCampaignForNonOmnichannel,
  } = props;

  const customGoBackUrl = isAssetEditMode
    ? `${Urls.RATE_CARD_EDIT}${loadedPackage ? `/${loadedPackage.rateCardId}` : ''}`
    : Urls.SHOW_ALL_CAMPAIGNS;

  const [goBackUrl, setGoBackUrl] = useState(customGoBackUrl);

  useEffect(() => {
    // Assets don't have any comments available in the Form view
    // so we don't need to trigger unnecessary logic
    if (isAssetEditMode) {
      return;
    }
    if (history.location
      && history.location.state
      && history.location.state.backUrl
    ) {
      setGoBackUrl(history.location.state.backUrl);
    }
    if (defaultOnlineCampaignForNonOmnichannel
      && defaultOnlineCampaignForNonOmnichannel.id) {
      onSetActiveCampaign(defaultOnlineCampaignForNonOmnichannel.id, loadedPackage.id);
      onStartCommentsPolling();
    }

    /* eslint-disable consistent-return */
    return () => {
      onResetComments();
      onStopCommentsPolling();
    };
  }, [
    isAssetEditMode,
    JSON.stringify(loadedPackage),
    onResetComments,
    onSetActiveCampaign,
    onStartCommentsPolling,
    onStopCommentsPolling,
  ]);

  useEffect(() => {
    const initialTab = loadedPackage && loadedPackage.campaigns ? loadedPackage.campaigns.findIndex(({ id }) => id === campaignId) : null;
    if ((initialTab || initialTab === 0) && isOmnichannelMode) {
      onSwitchOmnichannelTab(initialTab);
    }
  }, [JSON.stringify(loadedPackage)])

  const onModalClose = () => {
    history.push({ pathname: goBackUrl });
  };

  const onCloseButton = () => {
    if (shouldShowOnCloseConfirmation
      && !window.confirm(Language.ON_MODAL_FORM_CLOSE_CONFIRMATION)) {
      return;
    }
    onModalClose();
  };

  const buttonGoBack = () => (
    <span className={styles.closeButton}>
      <Button type="icon" onClick={onCloseButton} backdropColor="white">
        <Icon tokens={closeLargeTokens}>Close</Icon>
      </Button>
    </span>
  );

  const getForm = () => {
    return (
      <PackageForm
        isNew={isNew}
        isClone={isClone}
        campaignId={campaignId}
        loadedPackage={loadedPackage}
        onModalClose={onModalClose}
        isAssetEditMode={isAssetEditMode}
      />
    );
  };

  const getComments = () => {
    if (!defaultOnlineCampaignForNonOmnichannel) {
      return null;
    }

    return (
      <CommentsList campaignId={defaultOnlineCampaignForNonOmnichannel.id} />
    );
  };

  const getComponentByTab = () => {
    switch (currentTab) {
      case nonOmnichannelPackageTabList.FORM.name:
        return getForm();
      case nonOmnichannelPackageTabList.COMMENTS.name:
        return getComments();
      default:
        return getForm();
    }
  };

  const getNonOmnichannelExperience = () => {
    return (
      <>
        <PackageHeader
          isAssetEditMode={isAssetEditMode}
          isNew={!isNew}
          loadedCampaign={defaultOnlineCampaignForNonOmnichannel}
        />
        {getComponentByTab()}
      </>
    );
  };

  const getOmnichannelExperience = () => {
    return (
      <>
        <PackageHeader
          isAssetEditMode={isAssetEditMode}
          isNew={isNew}
        />
        {getForm()}
      </>
    );
  };

  const getContent = () => {
    if (isLoading && !isNew) {
      return Language.GENERIC_LOADING;
    }

    return isOmnichannelMode
      ? getOmnichannelExperience()
      : getNonOmnichannelExperience();
  };

  const goBackButton = buttonGoBack();
  const content = getContent();

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.wrapper}>
        {goBackButton}
        <div className={styles.wrapperInner}>
          {content}
        </div>
      </div>
    </>
  );
};

Package.propTypes = {
  currentTab: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isClone: PropTypes.bool,
  isAssetEditMode: PropTypes.bool,
  onSetActiveCampaign: PropTypes.func.isRequired,
  onStartCommentsPolling: PropTypes.func.isRequired,
  onStopCommentsPolling: PropTypes.func.isRequired,
  onResetComments: PropTypes.func.isRequired,
  shouldShowOnCloseConfirmation: PropTypes.bool.isRequired,
};

Package.defaultProps = {
  isClone: false,
  isAssetEditMode: false,
};

export default Package;
