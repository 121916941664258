import {
  CampaignColumns,
  CampaignStatuses,
  CampaignTypes,
  PlacementSource,
} from '../constants';
import { createItemShell } from './common';
import { isOnlineCampaign } from './campaign';

const getPackageColumns = () => {
  return [
    CampaignColumns.BUSINESS_START_YEAR,
    CampaignColumns.BRAND,
    CampaignColumns.CATEGORY,
    CampaignColumns.STATUS,
    CampaignColumns.START_DATE,
    CampaignColumns.END_DATE,
    CampaignColumns.START_TIME,
    CampaignColumns.END_TIME,
    CampaignColumns.MARKETING_MANAGER,
    CampaignColumns.MARKETING_MANAGER_NOTES,
    CampaignColumns.PACKAGE_NAME,
    CampaignColumns.PROGRAM,
    CampaignColumns.PLACEMENT_SOURCE,
    CampaignColumns.RATE_CARD_ID,
    CampaignColumns.REF_MARKER,
    CampaignColumns.TIMEZONE_ID,
    CampaignColumns.VERTICAL,
    CampaignColumns.VENDOR,
    CampaignColumns.IS_DEAL,
  ]
};

export const getOmnichannelPackageCampaignSyncExcludedColumnNames = () => {
  return [
    CampaignColumns.RATE_CARD_ID.name,
    CampaignColumns.REF_MARKER.name,
    CampaignColumns.MARKETING_MANAGER_NOTES.name,
  ];
}

export const getNonOmnichannelPackageCampaignSyncExcludedColumnNames = () => {
  return [
    CampaignColumns.RATE_CARD_ID.name,
  ];
}

export const getPackageCampaignSyncExcludedColumnNames = (isOmnichannel) => {
  return isOmnichannel
    ? getOmnichannelPackageCampaignSyncExcludedColumnNames()
    : getNonOmnichannelPackageCampaignSyncExcludedColumnNames();
};

export const getPackageColumnNames = () => getPackageColumns().map(({ name }) => name);

export const isPackage = (item) => {
  const { id, packageId } = item;

  if (!id && !packageId) {
    if (item.hasOwnProperty(CampaignColumns.CAMPAIGN_TYPE.name)) {
      return false;
    }
    if (item.hasOwnProperty('campaigns')) {
      return true;
    }
  }

  return (id && !packageId)
      || !item.hasOwnProperty(CampaignColumns.PACKAGE_ID.name);
};

export const createPackageShell = (packageOverrides = {}, campaigns = []) => {
  const packageShell = createItemShell(getPackageColumns(), packageOverrides);
  return {
    ...packageShell,
    campaigns,
  };
};

export const isPlacementSourceVssm = (placementSource) => {
  return placementSource === PlacementSource.VSSM;
}

export const getInStoreCampaigns = (campaigns) => {
  if (!campaigns) {
    return [];
  }

  return campaigns.filter(campaign => !isOnlineCampaign(campaign));
}

// this solution is not optimal due to the dependence on the package naming convention, but the only solution for now
export const isExclusiveInStorePackage = (packageName) => {
  return packageName ? packageName.includes(CampaignTypes.IN_STORE) : false;
}

export const isOmnichannelPackageName = (packageName) => {
  return packageName ? packageName.includes('Omnichannel') : false;
}

export const getPackageWatchableFieldsForCampaignNameChange = () => {
  return [
    CampaignColumns.VERTICAL.name,
    CampaignColumns.VENDOR.name,
  ];
};

export const isPackageColumn = (fieldName) => {
  return getPackageColumnNames().includes(fieldName);
}

export const getCombinedStatusForPackage = (pkg) => {
  const campaignStatuses = pkg.campaigns.map(campaign => campaign.status);

  if (campaignStatuses.includes(CampaignStatuses.MISSING_INPUTS)) {
    return CampaignStatuses.MISSING_INPUTS;
  }
  if (campaignStatuses.every(status => status === CampaignStatuses.INPUTS_COMPLETE)) {
    return CampaignStatuses.INPUTS_COMPLETE;
  }
  if (campaignStatuses.every(status => status === CampaignStatuses.APPROVED)) {
    return CampaignStatuses.APPROVED;
  }

  return pkg.status;
};