import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Loader from '@amzn/meridian/loader';
import classnames from 'classnames';
import BusinessGroup from './BusinessGroup';
import Description from './Description';
import MarketingType from './MarketingType';
import Objective from './Objective';
import { Language } from 'src/constants';
import styles from './ContentSymphonyModal.module.scss';

const ContentSymphonyModal = ({
  isOpen,
  onCloseModal,
  owner,
  displayName,
  creativeData,
  validationErrors,
  onCreate,
  isRunning,
  isCreateCsCampaignEnabled,
}) => {
  const performCreate = () => {
    onCreate(creativeData);
  };

  const spinner = () => {
    return isRunning ? (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinnerWrapper}>
          <Loader />
        </div>
        {Language.CREATE_UPDATE_SYMPHONY_CAMPAIGN_LOADER_TEXT}
      </div>
    ) : null;
  };

  const displayErrorMessage = (errorMessage) => {
    return errorMessage
      ? <p className={styles.errorMessageWithError}>{errorMessage}</p>
      : <p className={styles.errorMessageWithoutError}>Everything seems good</p>;
  };

  const getButtonText = () => {
    const submitText = !isCreateCsCampaignEnabled
      ? 'Update Creative'
      : 'Create Creative';

    return validationErrors ? 'Continue' : submitText;
  };

  const getSubmitButton = () => {
    return (
      <Button
        type="primary"
        size="small"
        onClick={performCreate}
        disabled={isRunning}
        data-testid="create"
      >
        {getButtonText()}
      </Button>
    );
  };

  const errorMessageDiv = validationErrors ? (
    <div className={classnames(styles.errorMessageSection, styles.inputGroup)}>
      There may be some errors in your inputs that could cause issues in Symphony.
      Please refer to the error messages and fix them if necessary.
      If you have reviewed these messages and believe the inputs are correct,
      please click on the Continue button.
      <div className={styles.errorMessageTitle}>
        Creative:
        {displayErrorMessage(validationErrors.creativeErrorMessage)}
      </div>
      <div className={styles.errorMessageTitle}>
        Desktop placement:
        {displayErrorMessage(validationErrors.desktopPlacementErrorMessage)}
      </div>
      <div className={styles.errorMessageTitle}>
        Mobile placement:
        {displayErrorMessage(validationErrors.mobilePlacementErrorMessage)}
      </div>
      <div className={styles.errorMessageTitle}>
        Content:
        {displayErrorMessage(validationErrors.contentErrorMessage)}
      </div>
    </div>
  ) : null;

  const submitButton = getSubmitButton();

  return (
    <Modal open={isOpen}>
      {spinner()}
      <div className={styles.inputGroup}>
        <div>
          <div className={styles.inputTitle}>Title</div>
          <Input disabled value={displayName} size="small" />
        </div>
        <div>
          <div className={styles.inputTitle}>Owner</div>
          <Input disabled value={owner} size="small" />
        </div>
        <div>
          <div className={styles.inputTitle}>Description</div>
          <Description />
        </div>
        <div>
          <div className={styles.inputTitle}>Objective</div>
          <Objective />
        </div>
        <div>
          <div className={styles.inputTitle}>Marketing Type</div>
          <MarketingType />
        </div>
        <div>
          <div className={styles.inputTitle}>Business Group</div>
          <BusinessGroup />
        </div>
      </div>

      <ModalFooter>
        <Row alignment="center right" widths="fit">
          <Button
            type="secondary"
            size="small"
            onClick={onCloseModal}
            disabled={isRunning}
            data-testid="close"
          >
            Cancel
          </Button>
          {submitButton}
        </Row>
      </ModalFooter>
      {errorMessageDiv}
    </Modal>
  );
};

ContentSymphonyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  owner: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  creativeData: PropTypes.shape({
    businessGroup: PropTypes.string,
    campaignId: PropTypes.string,
    description: PropTypes.string,
    displayName: PropTypes.string,
    intent: PropTypes.string,
    objective: PropTypes.string,
    owner: PropTypes.string,
  }).isRequired,
  validationErrors: PropTypes.shape({
    creativeErrorMessage: PropTypes.string,
    desktopPlacementErrorMessage: PropTypes.string,
    mobilePlacementErrorMessage: PropTypes.string,
    contentErrorMessage: PropTypes.string,
  }),
  onCreate: PropTypes.func.isRequired,
  isRunning: PropTypes.bool.isRequired,
  isCreateCsCampaignEnabled: PropTypes.bool.isRequired,
};

ContentSymphonyModal.defaultProps = {
  validationErrors: null,
};

export default ContentSymphonyModal;
