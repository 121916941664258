import {
  onOpenToast,
  onRateCardAssetUpdateReceived,
} from '../../actionCreators';
import {
  GraphQLLabels,
  Language,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import onGraphQLAction from '../../actionCreators/graphql';
import { updateAsset } from '../../graphql/mutations';
import {
  translateAssetFromAppsyncToLocal,
  translateAssetFromLocalToAppsync,
} from '../../helpers/translateRateCardAssets';

export default (dispatch, getState, action, next) => {
  const {
    payload: {
      asset: {
        rateCardId,
        id,
        campaign: asset,
        version,
      },
      isFromBulkImport,
    },
  } = action;
  const {
    User: { currentUser: { username } },
    RateCard: { cards },
    Meta: { metadata },
  } = getState();

  const cardToEdit = cards.find((card) => card.id === rateCardId);
  if (!cardToEdit) {
    return next(action);
  }

  const translatedAsset = translateAssetFromLocalToAppsync(asset);

  const dataToSend = {
    asset: {
      rateCardId,
      id,
      campaign: JSON.stringify(translatedAsset),
      version: version + 1,
    },
    username,
  };

  delete dataToSend.version;

  const metricContext = {
    location: MetricLocations.RATE_CARD_UPDATE_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: dataToSend,
    action: MetricNames.UPDATE_RATE_CARD_ASSET,
    username,
  };

  submitStringMetric(MetricNames.UPDATE_RATE_CARD, metricContext);
  dispatch(onGraphQLAction({
    graphqlAction: updateAsset,
    data: { input: dataToSend },
    label: GraphQLLabels.UPDATE_RATE_CARD_ASSET,
    onSuccessFunction({ data: { updateAsset: response } }) {
      const newTranslatedAsset = {
        ...response,
        campaign: translateAssetFromAppsyncToLocal(JSON.parse(response.campaign), metadata),
      };

      dispatch(onRateCardAssetUpdateReceived(newTranslatedAsset));
      if (!isFromBulkImport) {
        dispatch(onOpenToast(Language.RATE_CARD_ASSET_UPDATED_MESSAGE));
      }
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
