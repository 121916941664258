/* eslint-disable no-param-reassign, no-mixed-operators */
import { CampaignColumns, Comparators, Urls } from '../constants';
import {
  merchIntentValidationRules,
  RateCardConfigNames,
  RateCardExportColumnsAllowedSet,
  RateCardMediaPackage,
  RateCardMerchTypes,
} from '../constants/rateCard';
import getConfig from './getRateCardConfig';
import { createFilter, createFilterString } from './filters';
import { trimAndLowerCase } from './common';
import { CampaignType } from '../constants/campaign';
import { isInStoreCampaign, isOnlineCampaign } from './campaign';

export const getRateCardById = (cards, id) => cards.find((card) => card.id === id);
export const getRateCardAssetById = (assets, id) => assets.find((asset) => asset.id === id);

export const getMerchantClassesList = () => [
  RateCardMerchTypes.AWARENESS.name,
  RateCardMerchTypes.CONSIDERATION.name,
  RateCardMerchTypes.CONVERSION.name,
];

export const calcTotalInvestment = (totalInvestment, params = {}) => {
  const {
    hasEarlyBirdDiscount = false,
    configName = null,
  } = params;

  if (!totalInvestment || totalInvestment < 100) {
    return 0;
  }

  const config = getConfig(configName);
  const multipliersConfig = config.getMultipliersConfig();

  const pointsMultiplier = hasEarlyBirdDiscount
    ? multipliersConfig.EARLY_BIRD
    : multipliersConfig.STANDARD;

  return Math.ceil(totalInvestment * pointsMultiplier);
};

export const getSpreadLimits = ({ className }) => {
  return merchIntentValidationRules[className];
};

export const getRateCardExportColumns = (columnsSetName) => {
  if (getRateCardExportColumns.cache[columnsSetName]) {
    return getRateCardExportColumns.cache[columnsSetName];
  }
  if (!Object.keys(columnsSetName).length) {
    return {};
  }
  const columnsList = RateCardExportColumnsAllowedSet[columnsSetName];
  if (columnsList === undefined) {
    return {};
  }
  if (!Object.keys(columnsList).length) {
    const newColumnsAllowedList = {};
    Object.keys(CampaignColumns).forEach((key) => {
      newColumnsAllowedList[CampaignColumns[key].name] = true;
    });

    getRateCardExportColumns.cache[columnsSetName] = newColumnsAllowedList;

    return newColumnsAllowedList;
  }

  getRateCardExportColumns.cache[columnsSetName] = columnsList;

  return columnsList;
};
getRateCardExportColumns.cache = {};

export const getMediaPackageByValue = (value) => {
  if (RateCardMediaPackage.TEN_MILLIONS.value === value) {
    return RateCardMediaPackage.TEN_MILLIONS.name;
  }
  if (RateCardMediaPackage.FIFTEEN_MILLIONS.value === value) {
    return RateCardMediaPackage.FIFTEEN_MILLIONS.name;
  }
  if (RateCardMediaPackage.TWENTY_MILLIONS.value === value) {
    return RateCardMediaPackage.TWENTY_MILLIONS.name;
  }

  return '';
};

const hasPlacementTypeMatch = ({ placementName }, placementTypes) => {
  return trimAndLowerCase(placementTypes).find((placementType) => {
    return trimAndLowerCase(placementName).includes(placementType);
  });
};

export const getPlacementsCount = (placements, placementTypes = []) => {
  return placements.reduce((totalCount, placement) => {
    let count = 0;
    const { plannedPlacements } = placement;

    if (placementTypes && placementTypes.length
      && !hasPlacementTypeMatch(placement, placementTypes)) {
      return totalCount;
    }

    if (plannedPlacements) {
      count = plannedPlacements;
    }

    totalCount += parseInt(count, 10);

    return totalCount;
  }, 0);
};

export const getPublishedCampaignListUrl = (rateCardId) => {
  if (!rateCardId) {
    return '';
  }

  const filters = [
    createFilter(
      CampaignColumns.RATE_CARD_ID.name,
      Comparators.EQUALS,
      rateCardId,
    ),
  ];

  return `${Urls.SHOW_ALL_CAMPAIGNS}?${createFilterString({ filters })}`;
};

const getPlacementCost = (dollars, quantity) => {
  const formattedDollar = parseInt(dollars, 10) || 0;
  const formattedQuantity = parseInt(quantity, 10) || 0;

  return formattedDollar * formattedQuantity;
};

export const getMoneyUsedPerMerchantClass = (placements) => {
  const merchantClasses = getMerchantClassesList();
  const merchantClassCosts = merchantClasses.reduce(
    (allMerchantClasses, merchantClass) => ({
      ...allMerchantClasses,
      [merchantClass]: 0,
    }), {},
  );

  if (!placements || !placements.length) {
    return merchantClassCosts;
  }

  placements.forEach(({
    className, dollars, plannedPlacements,
  }) => {
    merchantClasses.forEach((merchantClass) => {
      if (trimAndLowerCase(className).indexOf(merchantClass) > -1) {
        merchantClassCosts[merchantClass] += getPlacementCost(
          dollars,
          plannedPlacements,
        );
      }
    });
  });

  return merchantClassCosts;
};

export const getTotalMoneyUsedAcrossMerchantClasses = (merchantClasses) => Object
  .keys(merchantClasses)
  .reduce((totalMoneyUsed, merchantClassName) => {
    return totalMoneyUsed + merchantClasses[merchantClassName];
  }, 0);

export const hasBusinessTypeMatch = ({ isAfs, isUff }) => {
  return !!parseInt(isAfs, 10) || !!parseInt(isUff, 10);
};

export const getStartYearFromRateCardConfigName = (configName) => {
  const { label: rateCardYear } = Object.values(RateCardConfigNames).find((configValue) => configValue.name === configName)
  return Number(rateCardYear) + 1;
}

export const getPlacementsByType = (placements, placementsConfig) => {
  const placementsByType = {
    [CampaignType.ONLINE]: [],
    [CampaignType.IN_STORE]: [],
    ['N/A']: [],
  };

  placements.forEach(placementName => {
    const placement = placementsConfig[placementName];
    if (!placement) {
      return;
    }
    if (isOnlineCampaign(placement)) {
      placementsByType[CampaignType.ONLINE].push(placementName);
    }
    else if (isInStoreCampaign(placement)) {
      placementsByType[CampaignType.IN_STORE].push(placementName);
    } else {
      placementsByType['N/A'].push(placementName);
    }
  });

  return placementsByType;
};