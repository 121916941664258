import _ from 'lodash';
import {
  CreateCsCampaignActions,
  CsDiffScreenTabs,
} from 'src/constants';

export const clearedState = {
  isModalOpen: false,
  isDiffModalOpen: false,
  isRunning: false,
  isCreateCsCampaignEnabled: false,
  shouldValidateCsCampaignInput: true,
  diffViewMode: CsDiffScreenTabs.ALL,
  validationErrors: null,
  campaign: {},
  creativeData: {},
  placementData: {},
  contentData: {},
  csCampaignIdsData: {},
  campaignDiff: {},
};

export const initialState = {
  ...clearedState,
  creativeData: {
    intents: null,
    objectives: null,
    businessGroups: null,
  }
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case CreateCsCampaignActions.OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true,
      };
    }
    case CreateCsCampaignActions.CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false,
      };
    }
    case CreateCsCampaignActions.OPEN_DIFF_MODAL: {
      return {
        ...state,
        isDiffModalOpen: true,
      };
    }
    case CreateCsCampaignActions.CLOSE_DIFF_MODAL: {
      return {
        ...state,
        isDiffModalOpen: false,
      };
    }
    case CreateCsCampaignActions.CLEAR_DATA: {
      return {
        ...state,
        ...clearedState,
      };
    }
    case CreateCsCampaignActions.SET_CAMPAIGN_DATA: {
      const {
        campaign,
        creativeData,
        placementData,
        contentData,
        csCampaignIdsData,
        isCreateCsCampaignEnabled
      } = action.data;

      return {
        ...state,
        campaign: _.isEmpty(campaign) ? state.campaign : campaign,
        creativeData: {
          ...state.creativeData,
          ...creativeData,
        },
        placementData: {
          ...state.placementData,
          ...placementData,
        },
        contentData: {
          ...state.contentData,
          ...contentData,
        },
        csCampaignIdsData: {
          ...state.csCampaignIdsData,
          ...csCampaignIdsData,
        },
        isCreateCsCampaignEnabled: (
          typeof isCreateCsCampaignEnabled !== 'undefined'
            ? isCreateCsCampaignEnabled
            : state.isCreateCsCampaignEnabled
        ),
      };
    }
    case CreateCsCampaignActions.SET_METADATA: {
      const {
        intents,
        objectives,
        accessControlGroups,
        businessGroups,
      } = action.data;
      return {
        ...state,
        intents,
        objectives,
        accessControlGroups,
        businessGroups,
      };
    }
    case CreateCsCampaignActions.SET_RUNNING_STATE: {
      return {
        ...state,
        isRunning: action.data,
      };
    }
    case CreateCsCampaignActions.SET_VALIDATION_ERRORS: {
      const {
        creativeErrorMessage,
        desktopPlacementErrorMessage,
        mobilePlacementErrorMessage,
        contentErrorMessage,
      } = action.data;
      return {
        ...state,
        shouldValidateCsCampaignInput: false,
        validationErrors: {
          creativeErrorMessage,
          desktopPlacementErrorMessage,
          mobilePlacementErrorMessage,
          contentErrorMessage,
        },
      };
    }
    case CreateCsCampaignActions.CLEAR_VALIDATION_ERRORS: {
      return {
        ...state,
        shouldValidateCsCampaignInput: true,
        validationErrors: null,
      };
    }
    case CreateCsCampaignActions.SET_CAMPAIGN_DIFF: {
      return {
        ...state,
        campaignDiff: action.data,
      };
    }
    case CreateCsCampaignActions.ON_CHANGE_DIFF_VIEW_MODE: {
      return {
        ...state,
        diffViewMode: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
