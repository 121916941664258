import {
  GraphQLLabels, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphql';
import { deleteAsset } from '../../graphql/mutations';
import { submitAppSyncError, submitError } from '../../metrics';

export default (dispatch, getState, action, next) => {
  const { User: { currentUser: { username } } } = getState();
  const { payload: { asset } } = action;

  const metricContext = {
    location: MetricLocations.RATE_CARD_DELETE_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.DELETE_RATE_CARD_ASSET,
    username,
  };

  const { campaign, version } = asset;
  const input = {
    asset: {
      ...asset,
      campaign: JSON.stringify(campaign),
      version: version +1,
    },
    username,
  };

  dispatch(onGraphQLAction({
    graphqlAction: deleteAsset,
    data: {
      input,
    },
    label: GraphQLLabels.DELETE_RATE_CARD_ASSET,
    onSuccessFunction({ data: { deleteAsset: response } }) {
      if (!response) {
        submitError('deleteAsset returned an empty result', metricContext);
      }
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
