import moment from 'moment';
import {
  onInStoreUtilizationSelectDateRange,
  onInStoreUtilizationSelectTimezone,
  onInStoreUtilizationSetFilters,
} from 'src/actionCreators';
import { createDateRange } from '../../helpers/instoreUtilization';
import {
  getDefaultTimezoneFromState,
} from '../../helpers/dateTime';
import { InStoreUtilizationFilterGroupNames } from '../../constants/inStoreUtilization';
import { CampaignStatuses } from '../../constants';

const getDefaultFilters = (filterOptions, overrideFilterOptions = {}) => {
  const filters = [];

  Object.entries(filterOptions).forEach(([filterName, options]) => {
    const selectedValues = {};
    options.forEach(({ value }) => {
      const hasOptionOverride = (
        overrideFilterOptions[filterName]
        && typeof overrideFilterOptions[filterName][value] !== 'undefined'
      );
      selectedValues[value] = hasOptionOverride
        ? overrideFilterOptions[filterName][value]
        : true;
    });
    filters.push({
      filterName,
      selectedValues,
    });
  });

  return filters;
};

export default (dispatch, getState, action, next) => {
  const state = getState();
  const {
    Sitewide: { startYear },
    InStoreUtilizationPage: {
      filterOptions,
    },
  } = state;
  const defaultDateRange = startYear && startYear !== moment().year().toString()
    ? createDateRange(1, startYear)
    : createDateRange();
  dispatch(onInStoreUtilizationSelectDateRange(defaultDateRange));

  const defaultTimezone = getDefaultTimezoneFromState(state);
  dispatch(onInStoreUtilizationSelectTimezone(defaultTimezone));

  const overrideFilterOptions = {
    [InStoreUtilizationFilterGroupNames.STATUS.name]: {
      [CampaignStatuses.CANCELED]: false,
    },
  };
  const defaultFilters = getDefaultFilters(filterOptions, overrideFilterOptions);
  if (defaultFilters.length > 0) {
    defaultFilters.forEach(({ filterName, selectedValues }) => {
      dispatch(onInStoreUtilizationSetFilters(filterName, selectedValues));
    });
  }

  return next(action);
};
