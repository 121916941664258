import { connect } from 'react-redux';
import moment from 'moment';
import InStoreUtilizationCalendar from './InStoreUtilizationCalendar';
import { translateInStoreCampaignDataToTimeline } from '../../../helpers/utilization';
import { onInStoreUtilizationSelectTimeFrame } from 'src/actionCreators';
import {
  getInStoreUtilization,
} from '../../../selectors/inStoreUtilization';
import { Language } from '../../../constants';
import { getTemplateString } from '../../../helpers/common';

const mapStateToProps = (state) => {
  const {
    InStoreUtilizationPage: {
      timeFrame,
      selectedDateRange,
      utilization,
    },
  } = state;

  const [dateTimeStart, dateTimeEnd] = selectedDateRange;

  const formattedDateTimeStart = moment(dateTimeStart).format('MMM Do YYYY');
  const formattedDateTimeEnd = moment(dateTimeEnd).format('MMM Do YYYY');

  const headerText = getTemplateString(Language.UTILIZATION_IN_STORE_HEADER, {
    dateTimeStart: formattedDateTimeStart,
    dateTimeEnd: formattedDateTimeEnd,
    campaignCount: utilization.length || 'No',
  });

  return {
    timeFrame,
    dateTimeStart: formattedDateTimeStart,
    dateTimeEnd: formattedDateTimeEnd,
    timelineEventsData: translateInStoreCampaignDataToTimeline(getInStoreUtilization(state)),
    headerText,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    onTimeFrameChange(timeFrame) {
      dispatch(onInStoreUtilizationSelectTimeFrame(timeFrame));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InStoreUtilizationCalendar);
