import _ from 'lodash';
import extractAsins from 'src/helpers/extractAsins';
import window from 'src/helpers/window';
import { isWFMBusiness, isGenericFreshBusiness, getItemBusiness } from 'src/helpers/common';
import {
  CampaignColumns,
  ComposerUrls,
  CARET,
  WidgetTypes,
  LandingTypes,
  Urls,
  MelodyUrls,
  ASIN_DELIMETER,
} from 'src/constants';
import {
  onThirdPartyIFrameSetDestination,
  onThirdPartyIFrameClose,
  onThirdPartyIFrameSetId,
  onUpdateCampaignColumn,
  onSendCampaignEdit,
  onEditCampaign,
} from 'src/actionCreators';
import { getImagePathsForCampaign } from 'src/helpers/thirdPartyIFrame/composer';
import { getCampaign } from 'src/selectors/campaign';

const BASE_COMPOSER_URL = process.env.NODE_ENV === 'production' ? ComposerUrls.PROD : ComposerUrls.DEV;
const COMPOSER_REDIRECT_URL = `${window.location.protocol}//${window.location.host}/#/redirectLanding/${LandingTypes.COMPOSER}`;
const DEFAULT = 'DEFAULT';

const composerTemplateMappingFresh = {
  ENHSHOV: {
    [DEFAULT]: 'freshEnhancedWidget',
  },
  [DEFAULT]: {
    HERO: 'Multitile',
    'HERO Main': 'Multitile',
    'Half Tile': 'Halftile',
    'Deals Side Tile': 'Multitile',
    [DEFAULT]: 'Multitile',
  },
};

const composerModeMappingFresh = {
  'Deals Side Tile': 'Deals',
  [DEFAULT]: 'Evergreen',
};

const composerTemplateMappingWFM = {
  HERO: {
    Lifestyle: 'WFMLandingPageHero',
    Silo: 'WFMLandingPageHeroSilo',
    [DEFAULT]: 'WFMLandingPageHero',
  },
  'Multi Tile': {
    Lifestyle: 'WFMHalfTileLifestyle',
    Silo: 'WFMHalfTileASIN',
    [DEFAULT]: 'WFMHalfTileLifestyle',
  },
  'Side Tile': {
    [DEFAULT]: 'WFMMultiTile',
  },
  'Single ASIN': {
    [DEFAULT]: 'WFMSingleASINWidget',
  },
  [DEFAULT]: {
    [DEFAULT]: '',
  },
};

const getComposerTemplateFresh = (campaign) => {
  if (campaign) {
    const placementSet = composerTemplateMappingFresh[campaign.widget]
      || composerTemplateMappingFresh[DEFAULT];
    return placementSet[campaign.imageType] || placementSet[DEFAULT];
  }
  return composerTemplateMappingFresh[DEFAULT][DEFAULT];
};

const getComposerTemplateWFM = (campaign) => {
  if (campaign) {
    const placementSet = composerTemplateMappingWFM[campaign.widget]
      || composerTemplateMappingWFM[DEFAULT];
    return placementSet[campaign.imageType] || placementSet[DEFAULT];
  }
  return composerTemplateMappingWFM[DEFAULT][DEFAULT];
};

const getComposerTemplate = (campaign) => {
  if (isWFMBusiness(getItemBusiness(campaign))) {
    return getComposerTemplateWFM(campaign);
  }
  return getComposerTemplateFresh(campaign);
};

const getComposerMode = (campaign) => {
  if (!isGenericFreshBusiness(getItemBusiness(campaign))) { return ''; }
  if (campaign) {
    return composerModeMappingFresh[campaign.placement] || composerModeMappingFresh[DEFAULT];
  }
  return composerModeMappingFresh[DEFAULT];
};

const getASINObject = (ASIN) => {
  return {
    ASIN,
    imageType: 'ASIN',
  };
};

export const getCopy = (campaign) => {
  if (isGenericFreshBusiness(getItemBusiness(campaign))) {
    if (campaign.copy.indexOf(CARET) !== -1) {
      return campaign.copy;
    }
    if (campaign) {
      const copy = campaign.widget === WidgetTypes.ENHSHOV ? campaign.subHeadline : campaign.copy;
      if (campaign.widget === WidgetTypes.ENHSHOV) {
        return copy;
      }
      return `${copy}`;
    }
  }
  return campaign.copy;
};

export const getSubHeadline = (campaign) => {
  if (campaign) {
    if (campaign.widget === WidgetTypes.ENHSHOV) {
      return '';
    }
    return campaign.subHeadline;
  }
  return '';
};

export const getAsinImages = (campaign) => {
  if (!campaign
    || !campaign.heroAsins
  ) {
    return [getASINObject('')];
  }
  return extractAsins(campaign.heroAsins).map(getASINObject);
};

export const getMelodyAsinImages = (campaign) => {
  if (!campaign || !campaign.heroAsins) {
    return '';
  }
  return extractAsins(campaign.heroAsins).join(ASIN_DELIMETER);
};


const getTermsAndCondition = (campaign) => {
  if (!campaign || !campaign.termsAndConditions) {
    return '';
  }

  return campaign.termsAndConditions;
};

const getLabel = (campaign) => {
  if (!campaign || !campaign.label) {
    return '';
  }

  return campaign.label;
};

const getRefMarker = (campaign) => {
  if (!campaign || !campaign.refMarker) {
    return '';
  }

  return campaign.refMarker;
};

const getCampaignName = (campaign) => {
  if (!campaign || !campaign.campaignName) {
    return '';
  }

  return campaign.campaignName;
};

const getWidget = (campaign) => {
  if (!campaign || !campaign.widget) {
    return '';
  }

  return campaign.widget;
};

const extractCreativeId = (url) => {
  if (!url) {
    return '';
   }  
  // Matches a creative ID that appears after '/summary/' in the URL
  const matches = url.match(/\/summary\/([^\/]+)/); 
  return matches[1] === 'undefined' ? '' : matches[1];
};

const getComposerSettings = (campaign) => {
  const images = getAsinImages(campaign);
  const mode = getComposerMode(campaign);
  const copy = getCopy(campaign);
  const subHeadline = getSubHeadline(campaign);
  const termsAndConditions = getTermsAndCondition(campaign);
  const label = getLabel(campaign);

  const settings = {
    apiVersion: '0.0.2',
    familyPackModifications: {
      mode,
      heading: {
        text: copy,
      },
      subheading: {
        text: subHeadline,
      },
      termsAndConditions: {
        text: termsAndConditions,
      },
      superheading: {
        text: label,
      },
      images,
    },
  };
  if (!mode) {
    delete settings.familyPackModifications.mode;
  }

  return settings;
};

const getMelodySettings = (campaign) => {
  const images = getMelodyAsinImages(campaign);
  const mode = getComposerMode(campaign);
  const copy = getCopy(campaign);
  const subHeadline = getSubHeadline(campaign);
  const termsAndConditions = getTermsAndCondition(campaign);
  const label = getLabel(campaign);
  const refMarker = getRefMarker(campaign);
  const campaignName = getCampaignName(campaign);
  const widget = getWidget(campaign);

  const settings = {
    apiVersion: '0.0.2',
    familyPackModifications: {
      mode,
    },
    brand: 'Fresh US',
    "Ref Marker": refMarker,
    "Campaign Name": campaignName,
    "T&C": termsAndConditions,
    "Label": label,
    "Copy": copy,
    "Sub-headline": subHeadline,
    "ASINs": images,
    "Widget": widget,
  }
  if (!mode) {
    delete settings.familyPackModifications.mode;
  }

  return settings;
};

export const getCampaignLink = ({ id }) => {
  if (!id) {
    return '';
  }

  return `${window.location.protocol}://${window.location.host}/#${Urls.EDIT_PACKAGE}/${id}`;
};

export const getComposerLink = (campaign) => {
  const settings = JSON.stringify(getComposerSettings(campaign));
  const encodedSettings = encodeURIComponent(btoa(unescape(encodeURIComponent(settings))));
  const campaignLink = btoa(getCampaignLink(campaign));
  const postUploadRedirectUrl = btoa(COMPOSER_REDIRECT_URL);
  const template = getComposerTemplate(campaign);

  const params = {
    t: template,
    postUploadRedirectUrl,
    instance: encodedSettings,
    postUploadAction: 'redirectWithFileLinks',
    campaignPath: campaignLink,
  };
  const queryParams = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

  return `${BASE_COMPOSER_URL}?${queryParams}`;
};

export const getMelodyLink = (campaign) => {
  const settings = JSON.stringify(getMelodySettings(campaign));
  const encodedSettings = encodeURIComponent(btoa(unescape(encodeURIComponent(settings))));
  const campaignLink = btoa(getCampaignLink(campaign));
  const postUploadRedirectUrl = btoa(COMPOSER_REDIRECT_URL);
  const template = getComposerTemplate(campaign);
  const melodyProjectId = campaign[CampaignColumns.MELODY_PROJECT_ID.name];
  const params = {
    t: template,
    postUploadRedirectUrl,
    instance: encodedSettings,
    postUploadAction: 'redirectWithFileLinks',
    campaignPath: campaignLink,
  };
  if (melodyProjectId) {
    params.melodyProjectId = melodyProjectId;
  }
  const queryParams = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

  return `${MelodyUrls.BETA}/?${queryParams}`;
};

export const openComposer = ({ getState, dispatch, action }) => {
  const { payload: { id, packageId } } = action;
  const campaign = getCampaign(getState(), id, packageId);
  const composerLink = getComposerLink(campaign, getState());

  dispatch(onThirdPartyIFrameSetId(id, packageId));
  dispatch(onThirdPartyIFrameSetDestination(composerLink));
};

export const openMelody = ({ getState, dispatch, action }) => {
  const { payload: { id, packageId } } = action;
  const campaign = getCampaign(getState(), id, packageId);
  const melodyLink = getMelodyLink(campaign, getState());

  dispatch(onThirdPartyIFrameSetId(id, packageId));
  dispatch(onThirdPartyIFrameSetDestination(melodyLink));
};

export const handleComposerEvent = ({ getState, dispatch, action }) => {
  const state = getState();
  const { ThirdPartyIFrame: { id, packageId } } = state;
  const campaign = getCampaign(state, id, packageId);
  if (campaign) {
    const { payload: { files, composerComponentJson } } = action;

    dispatch(onUpdateCampaignColumn(
      campaign,
      CampaignColumns.IMAGE_PATHS.name,
      getImagePathsForCampaign(campaign, files)
    ));

    if (composerComponentJson) {
      dispatch(onUpdateCampaignColumn(
        campaign,
        CampaignColumns.COMPOSER_COMPONENT_JSON.name,
        composerComponentJson
      ));
    }

    dispatch(onSendCampaignEdit(id, packageId));
    dispatch(onThirdPartyIFrameSetId(null, null));
    dispatch(onThirdPartyIFrameClose());
  }
};

export const handleMelodyEvent = ({ getState, dispatch, action }) => {
  const state = getState();
  const { ThirdPartyIFrame: { id, packageId } } = state;
  const { payload: { symphonyUrl, melodyProjectId } } = action;
  const campaign = getCampaign(state, id, packageId);

  if (campaign && symphonyUrl && melodyProjectId) {
    const creativeId = extractCreativeId(symphonyUrl);
    if (!_.isEmpty(creativeId)) {
      // TODO: Remove all instances of onUpdateCampaignColumn in 3P Integrations & possibly altogether (https://sim.amazon.com/issues/P192919284)
      dispatch(onUpdateCampaignColumn(
        campaign,
        CampaignColumns.SYMPHONY_URL.name,
        symphonyUrl
      ));
      // TODO: Do all the diff calculations to check if a campaign has changed in a consolidated middleware (https://sim.amazon.com/issues/V1652527293)
      if (!_.isEqual(symphonyUrl, campaign[CampaignColumns.SYMPHONY_URL.name])) {
        dispatch(onEditCampaign({
          ...campaign,
          [CampaignColumns.MELODY_PROJECT_ID.name]: melodyProjectId,
          [CampaignColumns.SYMPHONY_URL.name]: symphonyUrl,
          [CampaignColumns.CS_CAMPAIGN_IDS.name]: {
            creativeId,
          }
        }));
      }
      dispatch(onThirdPartyIFrameSetId(null, null));
      dispatch(onThirdPartyIFrameClose());
    }
  }
};