import { FormActions, PackageTabActions } from 'src/constants';

export const onPackageFormSetTab = (tabName, tabType) => ({
  type: PackageTabActions.SET_TAB,
  payload: {
    tabName,
    tabType,
  },
});
export const onCampaignFormSetUtilizationData = (data) => ({
  type: FormActions.SET_UTILIZATION,
  payload: data,
});
export const onCampaignFormSetValidation = ({ validationType, data }) => ({
  type: FormActions.SET_VALIDATION,
  payload: {
    validationType,
    data,
  },
});
export const onFormAddTab = (campaignType) => ({
  type: FormActions.ADD_TAB,
  payload: {
    campaignType
  },
});

export const onFormRemoveTab = (tabIndex) => ({
  type: FormActions.REMOVE_TAB,
  payload: {
    tabIndex,
  },
});
export const onFormSyncTabs = (tabIndex) => ({
  type: FormActions.SYNC_TABS,
  payload: {
    tabIndex,
  },
});