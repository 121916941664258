import { createSelector } from 'reselect';
import { BulkImportValidationModes } from '../constants';

export const getBulkImportValidationMode = (state) => {
  return state.BulkImport.validationMode;
}

export const isPartialBulkImportMode = createSelector(
  [getBulkImportValidationMode], (partialBulkImportMode) => {
    return partialBulkImportMode === BulkImportValidationModes.PARTIAL.name;
  },
);