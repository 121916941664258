import {
  onCreateCsCampaignSetCampaignData,
} from 'src/actionCreators';
import { convertCsCampaignIds } from 'src/helpers/translateCampaigns';
import {
  BusinessTypes,
} from 'src/constants';
import { getPackage } from 'src/selectors/package';

export const getOwner = (campaign, owningPackage, state) => {
  const { Sitewide: { selectedBusiness } } = state;
  if (selectedBusiness === BusinessTypes.WFM.name
      || selectedBusiness === BusinessTypes.NA_3P.name) {
    return campaign.scheduler;
  }
  return owningPackage.marketingManager;
};

const setDefaultCampaignData = (state, dispatch, action) => {
  const {
    ContentSymphony: {
      accessControlGroups,
      businessGroups,
      intents,
      objectives,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;
  const { payload: { campaign } } = action;
  const { id, packageId } = campaign;
  const owningPackage = getPackage(state, packageId);

  if (!campaign || !owningPackage) {
    dispatch(onCreateCsCampaignSetCampaignData({
      campaign: {},
      creativeData: {},
      placementData: {},
      contentData: {},
      csCampaignIdsData: {},
      isCreateCsCampaignEnabled: false,
    }));
  } else {
    const owner = getOwner(campaign, owningPackage, state);
    const defaultObjective = objectives.find((objective) => {
      return objective.label === 'Not Listed';
    });
    const defaultObjectiveValue = defaultObjective ? defaultObjective.value : '';
    const defaultIntent = intents[0].value;

    const {
      asins,
      campaignName,
      startDateTime,
      endDateTime,
      heroAsins,
      inventoryAwareness,
      merchant,
      pageId,
      pageType,
      sov,
      sovType,
      targeting,
      targetingExclude,
      weblab,
      weblabTreatment,
      widget,
      desktopSlot,
      mobileSlot,
      encodedKeplerWidgetParam,
      percolateParameters,
      csCampaignIds,
      symphonyUrl,
    } = campaign;

    const creativeData = {
      campaignId: id, // TODO This is no longer needed, must be removed first from Appsync
      owner,
      displayName: campaignName,
      objective: defaultObjectiveValue,
      intent: defaultIntent,
      businessGroup: businessGroups[selectedBusiness][0].value,
      accessControlGroupId: accessControlGroups[selectedBusiness][0],
      description: '',
    };

    const placementData = {
      business: selectedBusiness,
      heroASINs: heroAsins,
      inventoryAwareness,
      landingASINs: asins,
      merchant,
      pageId,
      pageType,
      sov,
      sovType,
      targeting,
      targetingExclude,
      weblab,
      weblabTreatment,
      widget,
      startTime: startDateTime,
      endTime: endDateTime,
      desktopSlot,
      mobileSlot,
    };

    const contentData = {};
    if (encodedKeplerWidgetParam) {
      contentData.widgetParameter = encodedKeplerWidgetParam;
    }
    if (percolateParameters) {
      contentData.percolateParameters = percolateParameters;
    }

    const csCampaignIdsData = convertCsCampaignIds(csCampaignIds);

    const isCreateCsCampaignEnabled = !symphonyUrl || symphonyUrl.length === 0;

    const campaignDataDefaults = {
      campaign,
      creativeData,
      placementData,
      contentData,
      csCampaignIdsData,
      isCreateCsCampaignEnabled,
    };

    dispatch(onCreateCsCampaignSetCampaignData(campaignDataDefaults));
  }
};

export default setDefaultCampaignData;
