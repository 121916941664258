import {
  CLOSE_TOAST,
  GridViewActions,
  HIDE_LOADING_SPINNER,
  KEYPRESS,
  ON_BUSINESS_RECEIVED,
  ON_YEARS_RECEIVED,
  OPEN_TOAST,
  PAGE_INIT,
  SELECT_BUSINESS,
  SELECT_YEARS,
  SHOW_LOADING_SPINNER,
  TOGGLE_SIDE_MENU,
} from 'src/constants';
import { SiteWideActions } from '../constants/sitewide';

export const onSwitchWebsiteViewMode = (viewMode) => ({
  type: SiteWideActions.SWITCH_WEBSITE_VIEW_MODE,
  payload: {
    viewMode,
  },
});
export const setAppSyncConnectionStatus = (
  isAlive,
  connectionType,
  error,
) => ({
  type: SiteWideActions.SET_APP_SYNC_CONNECTION_STATUS,
  payload: {
    isAlive,
    connectionType,
    error,
  },
});
export const setWebsiteReadOnlyMode = (isReadOnlyMode) => ({
  type: SiteWideActions.SET_WEBSITE_READ_ONLY_MODE,
  payload: {
    isReadOnlyMode,
  },
});
export const onShowLoadingSpinner = () => ({
  type: SHOW_LOADING_SPINNER,
});
export const onHideLoadingSpinner = () => ({
  type: HIDE_LOADING_SPINNER,
});
export const onOpenToast = (message, timeout) => ({
  type: OPEN_TOAST,
  data: {
    message,
    timeout,
  },
});
export const onCloseToast = (data) => ({
  type: CLOSE_TOAST,
  data,
});
export const toggleSideMenu = () => ({
  type: TOGGLE_SIDE_MENU,
});
export const onBusinessSelect = ({ business, forceReload }) => ({
  type: SELECT_BUSINESS,
  data: {
    business,
    forceReload,
  },
});
export const onBusinessReceived = (data) => ({
  type: ON_BUSINESS_RECEIVED,
  data,
});
export const onStartYearsSelect = (startYears) => ({
  type: SELECT_YEARS,
  payload: {
    startYears,
  },
});
export const onStartYearsReceived = (startYears) => ({
  type: ON_YEARS_RECEIVED,
  payload: { startYears },
});
export const onPageInit = () => ({
  type: PAGE_INIT,
});
export const toggleActionBar = () => ({
  type: GridViewActions.TOGGLE_ACTION_BAR,
});
export const onKeyboardPressAction = (event) => ({ type: KEYPRESS, data: event });