import {
  onOpenToast,
  onRateCardUpdatedReceived,
} from '../../actionCreators';
import {
  GraphQLLabels,
  Language,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import onGraphQLAction from '../../actionCreators/graphql';
import { updateRateCard } from '../../graphql/mutations';
import { appsyncToLocal, localToAppsync } from '../../helpers/translateRateCard';

export default (dispatch, getState, action, next) => {
  const { payload: { rateCard } } = action;
  const {
    User: { currentUser: { username } },
    RateCard: { cards },
    Sitewide: { selectedBusiness },
  } = getState();

  const cardToEdit = cards.find((card) => card.id === rateCard.id);
  if (!cardToEdit) {
    return next(action);
  }

  const input = {
    rateCard: {
      ...localToAppsync({ ...rateCard }),
      business: selectedBusiness,
      version: cardToEdit.version + 1,
    },
    username,
  };

  const metricContext = {
    location: MetricLocations.RATE_CARD_UPDATE_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: input,
    action: MetricNames.UPDATE_RATE_CARD,
    username,
  };

  submitStringMetric(MetricNames.UPDATE_RATE_CARD, metricContext);
  dispatch(onGraphQLAction({
    graphqlAction: updateRateCard,
    data: { input },
    label: GraphQLLabels.UPDATE_RATE_CARD,
    onSuccessFunction({ data: { updateRateCard: response } }) {
      const translatedLocalRateCard = appsyncToLocal(response);
      dispatch(onRateCardUpdatedReceived(translatedLocalRateCard));
      dispatch(onOpenToast(Language.RATE_CARD_CARD_UPDATED_MESSAGE));
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
