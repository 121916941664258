// TODO: These admins' lists will be replaced with functions (which represent LDAP groups) below
// once we establish LDAP groups and start using them

export const getAppAdminList = () => {
  return [
    'phonkhoi',
    'hanwooll',
    'vkryukov',
    'malpulki',
    'rushisp',
    // Fresh Central Marketing
    'nomilian',
    'alroepke',
    'hellmert',
    'dhvanis',
  ];
}

// https://w.amazon.com/bin/view/WWGS/Marketing/Marketing_Tools/Projects/Marketing_Calendar/Features/MetadataTool
export const getMetadataAdminList = () => [
  // WW Amazon Fresh, Worldwide Grocery Stores G&A / NA Fresh Retail Operations
  'petschek',
  'seanchok',
  'mcohena',
  // Amazon Fresh International, International Dabba / Prime Now Retail Fixed
  'buluci',
  // Online Fresh, NA Fresh Retail Operations / NA Fresh Retail Operations
  'clrsr',
  // US Prime / Offsite & Prime Marketing
  'tratrun',
  // Growth & Mktg, Worldwide Grocery Stores G&A
  'landeo', // F3 Central Marketing
  'maxibrow', // Events, F3 Central Marketing
  // Business Support - VAR / F3RS, Business Support - VAR
  'herrpul',
  // Inventory, NA Fresh Retail Operations / F3RS, Business Support - VAR
  'neismye',
  // Analytics and Tech, Supply Chain Execution-Central / NACF-PLEX-PPT
  'prerao',
  // Perishable, Category Mgmt - Amazon Fresh / Perishables, Category Mgmt - Amazon Fresh
  'rjshim',
  // WFMC metadata admins
  'fuyuzhuo',
  'pmoosrox',
  'colmjn',
  // Traffic admins
  'yusmeera',
  'megyoshi',
  // Fresh DE
  'lotteri',
  ...getAppAdminList(),
];

export const getMelodyTestingAdminList = () => {
  return [
    'rushisp',
    'malpulki',
    'vkryukov',
    'hanwooll',
    'phonkhoi',
    'nomilian',
    'alroepke',
    // Melody team
    'eugenbu',
    'ralumoca',
    'vararucv',
    'epistol',
    'chrisc',
    // MC testing users
    'neismye',
    'gsoesqui',
  ];
}

export const getLDAPAdminAccessGroups = () => [];
export const getLDAPMetadataAdminAccessGroups = () => [];
export const getLDAPReadOnlyAccessGroups = () => [];