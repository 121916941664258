import React from 'react';
import TimeSelect from '@amzn/meridian/time-select';
import { styledInputProps } from '../../proptypes';
import { getTimeOptions } from 'src/helpers/dateTime';
import styles from './StyledInputs.module.scss';

const Input = ({
  inputValue,
  onInputChange,
  onBlur,
  autoFocus,
}) => {
  return (
    <div className={styles.datePickerWrapper}>
      <TimeSelect
        value={inputValue || ''}
        onChange={onInputChange}
        onBlur={onBlur}
        size="small"
        autoFocus={autoFocus}
        options={getTimeOptions()}
      />
    </div>
  );
};

Input.propTypes = styledInputProps;

Input.defaultProps = {
  autoFocus: true,
};

export default Input;
