import {
  GraphQLLabels, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import onGraphQLAction from '../../actionCreators/graphql';
import { getUserSession } from '../../graphql/queries';
import { onNotificationsCreateLastSeenAt } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { User: { currentUser: { username } } } = getState();

  const metricContext = {
    location: MetricLocations.NOTIFICATIONS_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: {
      username,
    },
    action: MetricNames.CHECK_NOTIFICATIONS_LAST_SEEN_TIME,
    username,
  };

  submitStringMetric(MetricNames.CHECK_NOTIFICATIONS_LAST_SEEN_TIME, metricContext);

  dispatch(onGraphQLAction({
    graphqlAction: getUserSession,
    data: {
      username,
    },
    label: GraphQLLabels.GET_USER_SESSION,
    onSuccessFunction({ data: { getUserSession: userSessionData } }) {
      // First time user
      if (!userSessionData) {
        dispatch(onNotificationsCreateLastSeenAt());
      }
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
